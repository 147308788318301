<app-header></app-header>
<!-- ==== Header ==== -->
    <app-page-title heading1="OSHO NATARAJ MEDITATION" heading2="Osho Nataraj Meditation"></app-page-title>

    <section class="section-padding">
        <div class="container-fluid">
          <div class="row">
            <div class="col-sm-12">
            <div class="meditation-box">
              <img src="assets/img/Osho-Nataraj.jpg" class="img-fluid">
              <p>Nataraj is the energy of dance. This is dance as a total meditation, where all inner division disappears and a subtle, relaxed awareness remains.</p>

              <p>“Forget the dancer, the center of the ego; become the dance. That is the meditation. Dance so deeply that you forget completely that ‘you’ are dancing and begin to feel that you are the dance. The division must disappear; then it becomes a meditation.</p>

              <p>
                "If the division is there, then it is an exercise: good, healthy, but it cannot be said to be spiritual. It is just a simple dance. Dance is good in itself – as far as it goes it is good. After it, you will feel fresh, young. But it is not meditation yet. The dancer must go, until only the dance remains…. Don’t stand aside, don’t be an observer. Participate!
              </p>

              <p>
                "And be playful. Remember the word playful always – with me it is very basic.” Osho
              </p>

              <p>
                <strong>Instructions:</strong>
                The meditation has three stages, lasting a total of 65 minutes.
              </p>

              <p>
                  <strong>First Stage: 40 minutes</strong>
                  With eyes closed, dance as if possessed. Let your unconscious take over completely. Do not control your movements or witness what is happening. Just be totally in the dance.
              </p>

              <p>
                <strong>Second Stage: 20 minutes</strong>
                Keeping your eyes closed, lie down immediately. Be silent and still.
                </p>

                <p>
                    <strong>Third Stage: 5 minutes</strong>
                    Dance in celebration and enjoy.
                    </p>

            </div>
            </div>
          </div>
        </div>
    </section>


<!-- ==== Footer ==== -->
<app-front-end-footer></app-front-end-footer>