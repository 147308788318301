<app-header></app-header>
<!-- ==== Header ==== -->
    <app-page-title heading1="OSHO REMINDING YOURSELF OF THE FORGOTTEN LANGUAGE OF TALKING TO YOUR BODYMIND" heading2="Talking To Your Bodymind"></app-page-title>

    <section class="section-padding">
        <div class="container-fluid">
          <div class="row">
            <div class="col-sm-12">
            <div class="meditation-box">
              <img src="assets/img/talking-your-bodymind.jpg" class="img-fluid">
              <p>
                This is a guided one-week process, lasting one hour per day. It can then be continued or refreshed at any time.</p>

              <p>
                The method is based on the understanding that the body and mind are not separate they are really one entity, the bodymind, and we need to rediscover how to be friends with both parts. 
              </p>

              <p>
                Whatever happens in the mind goes to the body. And there are so many conflicts happening in the mind, through its thoughts and emotions, even conditioned ideas against our bodies, that this anguish and anxiety enters the body and causes energetic blocks and tensions there, which then appear as physical problems. 
              </p>

              <p>
                As Osho explains, “Change the body, the mind changes. Change the mind, the body changes.” And that “we have never been taught to talk to our own body – and miracles can happen through it.” What Osho calls, “the forgotten language” of communicating with the bodymind. 
              </p>

              <p>
                In a light trance, while combining deep relaxation with alertness, you learn to harness the bodymind’s creative and self-healing energies. These can be brought to any specific issues of imbalance or unease, such as smoking, eating imbalances, insomnia, aches and pains – any functions that are normally part of the body and which need to be brought to wholeness and balance again.
              </p>

            <p>
                <strong>Osho:</strong>
            </p>

            <p class="pl-3">
                <i>
                    The body will never misguide you, you can trust it, and you can trust it absolutely. Anything that goes against the body is forced upon you by others. That is a good criterion to find out what has been forced on you. Whatever goes against the body is forced on you, it is foreign. You should throw it out.
                </i>
            </p>

            <p>
                <strong>And:</strong>
            </p>

            <p class="pl-3">
                <i>
                    “The body has to be loved - you have to be a great friend. It is your home, you have to clean it of all junk, and you have to remember that it is in your service continuously, day in, day out. Even when you are asleep, your body is continuously working for you, digesting, changing your food into blood, taking out the dead cells from the body, bringing new oxygen, fresh oxygen into the body - and you are fast asleep!<br>
                    “It is doing everything for your survival, for your life, although you are so ungrateful that you have never even thanked your body. On the contrary, your religions have been teaching you to torture it: the body is your enemy and you have to get free from the body, its attachments. I also know that you are more than the body and there is no need to have any attachment. But love is not an attachment, compassion is not an attachment. Love and compassion are absolutely needed for your body and its nourishment.<br>
                    “And the better body you have, the more are the possibilities for growing consciousness. It is an organic unity.”
                </i>
            </p>

            

            </div>
            </div>
          </div>
        </div>
    </section>


<!-- ==== Footer ==== -->
<app-front-end-footer></app-front-end-footer>