import {  Injectable, Inject } from '@angular/core';
import { JsonPipe } from '@angular/common';
import {UserinfoModule} from './../model/userinfo/userinfo.module'

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {
apiUrl: string;
  constructor(@Inject('API_URL') baseUrl: string) {
    this.apiUrl=baseUrl;
   }


getBaseUrl(){
  return this.apiUrl;
}

saveUserDetail(obj){
  localStorage.setItem("userinfo",JSON.stringify(obj));
}

isUserLogin() {
  let data:UserinfoModule=JSON.parse(localStorage.getItem("userinfo"));
  let val;
  if(data){
    val =data.islogin;
  }
  return val;
}

  getUserToken() {
    let data:UserinfoModule=JSON.parse(localStorage.getItem("userinfo"));
    let val;
    if(data){
      val =data.token;
    }
    return val;
  }

  getuserId() {
    let data:UserinfoModule=JSON.parse(localStorage.getItem("userinfo"));
    let val:String;
    if(data){
      val =data.id;
    }
    return val;
  }

}
