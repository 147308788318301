<app-header></app-header>
<!-- ==== Header ==== -->
    <app-page-title heading1="OSHO BORN AGAIN" heading2="Osho Born Again"></app-page-title>

    <section class="section-padding">
        <div class="container-fluid">
          <div class="row">
            <div class="col-sm-12">
            <div class="meditation-box">
              <img src="assets/img/large.jpg" class="img-fluid">
              <p>
                This is a one-week process created by Osho to rediscover the beauty of childhood without verbalizing or analyzing our past psychological experiences.</p>

              <p>
                The course includes one hour of allowing yourself to just be a child again, and a second hour of silent sitting together as a group from about 10am to 12 noon. It also includes OSHO Dynamic Meditation at sunrise, OSHO Kundalini Meditation at sunset, followed by the OSHO Evening Meeting.
              </p>

              <p>
                For the first hour of the Multiversity course, you behave like a child. Just enter your childhood. Whatever you wanted to do, do it – dancing, singing, jumping, crying, weeping – anything at all, in any posture. Nothing is prohibited except touching other people. Don’t touch or harm anyone else in the group.
              </p>

              <p>
                <strong>BECOMING A CHILD AGAIN</strong>
                Once upon a time we were all real! Just naturally ourselves. When we wanted to laugh, we laughed. When we wanted to cry, we cried. When we were angry, we were angry. We danced and jumped and shrieked with delight. Playfulness was our essence. We lived in innocence, knowing nothing, except what we needed in that moment. 
              </p>

            <p>
                We were very small and needed the love of others, it was our only security. The price for that love was that we started “growing old,” rather than just naturally maturing and “growing up.” The day we became good boys and girls was a catastrophe. We stopped growing – we became artificial, unnatural, serious…. 
            </p>

            <p>
                Also, the world around us was so exciting and we wanted to go “out” and discover everything, and so we became so knowledgeable about everything except ourselves. In the process we forgot who we really are, and lost the innate ability of relaxing into not-knowing.
            </p>

            <p>
                Now is our chance to take ourselves back to that same fork in the road. This time we can consciously choose to take the path of playfulness, innocence, naturalness, laughter… rediscovering our originality, individuality, and freedom. And once we are on the road of authenticity, then finding ourselves, finding our center, finding our own inner joy will happen by itself.
            </p>

            <p>
                Then follows the hour of just sitting silently. You will be fresher, more innocent, and meditation will become easier.
            </p>

            <p>
                <strong>SITTING IN SILENCE</strong> 
                <i>“You are using awareness without being aware of it, but only about outside things. It is the same awareness that has to be used for the inside traffic. When you close your eyes there is a traffic of thoughts, emotions, dreams, imaginations; all kinds of things start flashing by.
                </i>
            </p>

            <p>
                “What you have been doing in the outside world, do exactly the same with the inside world and you will become a witness. And once tasted, the joy of being a witness is so great, so otherworldly that you would like to go more and more in…. From unawareness to awareness is the greatest quantum leap.” Osho
            </p>

            <p>
                <i>“This great experiment through which we are passing is basically to achieve your lost childhood again. When I say ‘your lost childhood,’ I mean your innocence, your eyes full of wonder, knowing nothing, having nothing, but yet feeling yourself at the top of the world. Those golden moments of wonder, joy, no tension, no worry, no anxiety, have to be regained, rediscovered. The sage is nothing but the circle that started in your birth coming full way, complete, back to the same point.”
                </i>
            </p>

            <p>
                “This is your second childhood. You are born again. Your eyes are no longer filled with the dust of knowing. But this not-knowing is a tremendous perceptivity, a great clarity. It does not need to know, because it feels, and feeling is a higher state than knowing. As the clarity becomes more and more transparent, instead of feeling it becomes your being.” Osho
            </p>

            </div>
            </div>
          </div>
        </div>
    </section>


<!-- ==== Footer ==== -->
<app-front-end-footer></app-front-end-footer>