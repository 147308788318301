<app-header></app-header>
<!-- ==== Header ==== -->
    <app-page-title heading1="OSHO WHIRLING MEDITATION" heading2="Osho Whirling Meditation"></app-page-title>

    <section class="section-padding">
        <div class="container-fluid">
          <div class="row">
            <div class="col-sm-12">
            <div class="meditation-box">
              <img src="assets/img/Osho-Devavani.jpg" class="img-fluid">
              <p>The meditation is best done on an empty stomach, on bare feet and wearing loose clothing. It lasts one hour and there are two stages, whirling and resting.</p>

              <p>Whirling is an ancient Sufi technique. While your whole body is moving you become aware of your very being, the watcher at the center, which is unmoving. You learn to be an unidentified witness at the center of the cyclone.</p>

              <p>
                "Whirling is one of the most ancient techniques, one of the most forceful. It is so deep that even a single experience can make you totally different. Whirl with open eyes, just like small children go on twirling, as if your inner being has become a center and your whole body has become a wheel, moving, a potter’s wheel, moving. You are in the center, but the whole body is moving." Osho
              </p>

              <p>
                  <strong>First Stage: 45 minutes</strong>
                  The whirling is done on one spot in an anti-clockwise direction, with the right arm held high, palm upwards, and the left arm low, palm downwards. You whirl just like small children go on twirling. People who feel discomfort from whirling anti-clockwise can change to clockwise, changing the position of the arms as well. Let your body be soft and keep your eyes open but unfocused, so that images become blurred and flowing. Remain silent.
              </p>

              <p>
                For the first 15 minutes, turn slowly. Then gradually build up speed until the whirling takes over and you become a whirlpool of energy – the periphery a storm of movement, the witness at the center silent and still.
              </p>
              <p>
                When you are whirling so fast that you cannot remain upright, your body will fall by itself. Don’t make the fall a decision on your part and do not try to arrange the landing in advance; if your body is soft you will land softly and the earth will absorb your energy. Once you have fallen, stay there, this is when the second part of the meditation starts for you.
              </p>

                <p>
                    <strong>Second Stage: 15 minutes</strong>
                    If you have not fallen down by the time the music stops, allow your body to fall to the ground. Immediately roll onto your stomach so that your navel is in contact with the earth. Feel your body blending into the earth, like a small child blends into the mother’s breasts. If anybody feels strong discomfort lying this way, he should lie on his back.<br>
                    Keep your eyes closed and remain passive and silent.
                </p>

            </div>
            </div>
          </div>
        </div>
    </section>


<!-- ==== Footer ==== -->
<app-front-end-footer></app-front-end-footer>