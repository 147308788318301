<app-header></app-header>
<!-- ==== Header ==== -->
<app-page-title heading1="Facilitators Details" heading2="Sindhu Prem"></app-page-title>

<section class="section-padding">
    <div class="container">
      <div class="row">
        <div class="col-sm-12">
        <div class="facilitation-box">
          <img src="assets/img/sindhu-prem.png" class="img-fluid">
            <p>Sindhu Prem has 25 years of experience in Meditation & Healing Therapies. She started her journey in 1995 as a Reiki Grandmaster and then a Patanjali Yoga teacher in 1999. She holds a Master Diploma in Holistic Medicine and has been trained as a Facilitator in OSHO Meditations, OSHO Mystic Rose, OSHO No-Mind, OSHO Born Again, OSHO Talking to Body-Mind, Transomatic Dialogue Therapy, Bardo- Karmic Past Life Healing, Akashic Processes. Tera-Mai-Sei-Chem, Reiki and Chakra Balancing, tantra practioner training. She facilitates individual sessions, and workshops in the OSHO  International Meditation Resort Pune  and also offers them all around the globe (Russia, Estonia, Greece, Italy, Turkey, Brazil, Taiwan, Bali, London, Hongkong, India Bangladesh.) Currently she is co -creating OSHO Himalayas; a meditation retreat centre near Dharamshala in Himachal Pradesh, India.</p>
        </div>
        </div>
      </div>
    </div>
</section>

<!-- ==== Footer ==== -->
<app-front-end-footer></app-front-end-footer>