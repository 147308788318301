<app-header></app-header>
<!-- ==== Header ==== -->
    <app-page-title heading1="OSHO CHAKRA BREATHING MEDITATION" heading2="Osho Chakra Breathing Meditation"></app-page-title>

    <section class="section-padding">
        <div class="container-fluid">
          <div class="row">
            <div class="col-sm-12">
            <div class="meditation-box">
              <p>This active meditation uses deep, rapid breathing and body movement to open and bring awareness, vitality and silence to each of the seven chakras and into your life. </p>

              <p>The meditation is best done on an empty stomach. <br>
                Music and bells support the process and signal the beginning of each stage. The meditation is to be done with its specific OSHO Chakra Breathing Meditation music, which indicates and energetically supports the different stages.</p>

              <p>
                * All chakras lie deep within, rather than on the surface of the body. The following “map” is used to indicate their approximate locations:<br>
                1. base chakra: the sex center, lower pelvis <br>
                2. sacral chakra: just below the navel <br>
                3. solar plexus chakra: above the navel, below the breastbone <br>
                4. heart chakra: the middle of the chest <br>
                5. throat chakra: the throat <br>
                6. third eye chakra: between the eyebrows <br>
                7. crown chakra: top of the head
              </p>

              <p>
                <strong>Instructions:</strong>
                The meditation lasts one hour and has two stages. It ends when you hear three gongs. Keep your eyes closed throughout.
              </p>

              <p>
                <strong>First stage: 45 minutes:</strong>
                Stand with your feet a little apart, your body loose and relaxed. With open mouth breathe deeply and rapidly into the first chakra, with equal emphasis on in and out breaths. Keep your attention in the pelvic area, where the first chakra is located. Breathe in a rhythm that feels comfortable and become aware of the feelings and sensations of the chakra.
              </p>

              <p>
                Each time you hear a bell, move this same deep, rapid breathing up to the next chakra, letting your breathing become more rapid and gentler. You will take about twice as many breaths in the seventh chakra than in the first. 
              </p>

              <p>
                You can shake, stretch, rotate or move your body and hands as you feel, but your feet stay in one spot. Once you set the breathing and body in motion, the movement will become continuous and effortless. Your awareness remains primarily with the sensations of the chakras.
              </p>

              <p>
                After breathing in the seventh chakra, you will hear three bells. Now let your breath and awareness turn and fall back down through each chakra, your breath slowing from chakra to chakra. You have about two minutes to reach back to the first chakra. Let the whole chakra spectrum from top to bottom blend into one rainbow of energy. 
              </p>

              <p>
                After this sequence, stand silently for a moment, then start the next sequence. You will complete three full sequences upward and downward.
              </p>

              <p>
                <strong>Second stage: 15 minutes</strong>
                After the third breathing sequence, sit relaxed and in silence. Remain a witness to whatever is happening within, without judgment.
              </p>


            </div>
            </div>
          </div>
        </div>
    </section>


<!-- ==== Footer ==== -->
<app-front-end-footer></app-front-end-footer>