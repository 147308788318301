import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import $ from "jquery";
import { OwlOptions } from 'ngx-owl-carousel-o';
import { NgxImageGalleryComponent, GALLERY_IMAGE, GALLERY_CONF } from "ngx-image-gallery";
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { HomepagesliderService } from './../../../../Services/homepageslider.service';
import { MessagePanelService } from './../../../../Services/message-panel.service';
import { Globals } from './../../../../global';
import { LocalStorageService } from './../../../../Services/local-storage.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-past-event',
  templateUrl: './past-event.component.html',
  styleUrls: ['./past-event.component.scss']
})
export class PastEventComponent implements OnInit {

  modalRef: BsModalRef;
  public data : any;
  title : any;
  startdate : any;
  enddate : any;
  description : any;
  getpasteventall:any =[];
  getsingledata:any =[];
  imgbaseurl="";

  @ViewChild(NgxImageGalleryComponent,  { static: false, }) ngxImageGallery: NgxImageGalleryComponent;

  // gallery configuration
  conf: GALLERY_CONF = {
    imageOffset: '0px',
    showDeleteControl: false,
    showImageTitle: false,
  };

  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    autoplay:true,
    autoplayHoverPause:false,
    lazyLoad:false,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    nav: true,
    navText: ['<i class="fa fa-angle-left" aria-hidden="true"></i>', 
              '<i class="fa fa-angle-right" aria-hidden="true"></i>'],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 2
      },
      740: {
        items: 3
      },
      940: {
        items: 4
      }
    },
  }

  customOptionsevent: OwlOptions = {
    loop: true,
    mouseDrag: true,
    autoplay:true,
    autoplayHoverPause:false,
    lazyLoad:false,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    nav: true,
    navText: ['<i class="fa fa-angle-left" aria-hidden="true"></i>', 
              '<i class="fa fa-angle-right" aria-hidden="true"></i>'],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 2
      },
      740: {
        items: 3
      },
      940: {
        items: 4
      }
    },
  }
  constructor(public globals: Globals, private router: Router, private formBuilder: FormBuilder, private messagePanelService: MessagePanelService, private localStorageService: LocalStorageService, public homepagesliderService:HomepagesliderService,private modalService: BsModalService) { }


  ngOnInit(): void {
    this.imgbaseurl=this.localStorageService.getBaseUrl();
    this.getpasteventalldata();
  }

    
  openEditModal(template: TemplateRef<any>, pastevent) {
    this.getsingledata = pastevent;
    console.log("PAST EVENT__________",this.getsingledata)
    this.title = this.getsingledata.event_title;
    this.startdate = this.getsingledata.event_startdate;
    this.enddate = this.getsingledata.event_enddate;
    this.description = this.getsingledata.event_desc;
    this.modalRef = this.modalService.show(template, {
      class:'modal-lg',
      keyboard: false,
      backdrop:'static'
    });
  }
  getpasteventalldata(){
    this.homepagesliderService.getpastevent().subscribe(data => {
      if (data === null || data === undefined) {
        this.messagePanelService.ShowPopupMessageWithLocalization('An error occured, please try again later', this.globals.messageCloseTime, this.globals.messageType.error);
      } else if (data['success'] === true) {
        if(data.Event.length>0){
          this.getpasteventall = data.Event;
          console.log("GET EVENT DATA ALL________",this.getpasteventall);
        }else{
          this.getpasteventall = data.Event;
          this.messagePanelService.ShowPopupMessageWithLocalization(data['message'], this.globals.messageCloseTime, this.globals.messageType.success);
        }
      } else if (data['statusCode'] == 400) {
        this.messagePanelService.ShowPopupMessageWithLocalization(data['message'], this.globals.messageCloseTime, this.globals.messageType.success);
      } else {
        this.messagePanelService.ShowPopupMessageWithLocalization(data['message'], this.globals.messageCloseTime, this.globals.messageType.error);
      }
    });
  }

  // METHODS
  // // open gallery
  // openGallery(index: number = 0) {
  //   console.log("EVENT______ID",index)
  //   this.ngxImageGallery.open(index);    
  // }
    
  // // close gallery
  // closeGallery() {
  //   this.ngxImageGallery.close();
  // }
    
  // // set new active(visible) image in gallery
  // newImage(index: number = 0) {
  //   this.ngxImageGallery.setActiveImage(index);
  // }
    
  // // next image in gallery
  // nextImage(index: number = 0) {
  //   this.ngxImageGallery.next();
  // }
    
  // // prev image in gallery
  // prevImage(index: number = 0) {
  //   this.ngxImageGallery.prev();
  // }
  
    
  // /**************************************************/
    
  // // EVENTS
  // // callback on gallery opened
  // galleryOpened(index) {
  //   console.info('Gallery opened at index ', index);
  // }
 
  // // callback on gallery closed
  // galleryClosed() {
  //   console.info('Gallery closed.');
  // }
 
  // // callback on gallery image clicked
  // galleryImageClicked(index) {
  //   console.info('Gallery image clicked with index ', index);
  // }
  
  // // callback on gallery image changed
  // galleryImageChanged(index) {
  //   console.info('Gallery image changed to index ', index);
  // }
 
  // // callback on user clicked delete button
  // deleteImage(index) {
  //   console.info('Delete image at index ', index);
  // }

}
