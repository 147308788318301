import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-osho-mystic-rose',
  templateUrl: './osho-mystic-rose.component.html',
  styleUrls: ['./osho-mystic-rose.component.scss']
})
export class OshoMysticRoseComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
