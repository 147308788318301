<div class="main-content">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-header card-header-danger">
                        <a class="delete-icon back-btn" title="Delete" routerLink="/event"><i class="fa fa-arrow-left action-icon" aria-hidden="true"></i>BACK</a>
                        
                        <button  class="add-btn " data-toggle="modal" (click)="openAddModal(addtemplate)"  data-target="#adddetail" ><i class="fa fa-plus-square action-icon" aria-hidden="true"></i>Upload Image</button>
                    </div>
                  
                  <div class="card-body">
                    <div class="row">
                      <div class="col-sm-3 mb-4"*ngFor="let eventImages of EventImagesList;let i = index" >
                        <div class="EImageBox">

                        <a class="example-image-link" href="{{getbase_url}}/{{eventImages.event_image}}" data-lightbox="example-set" data-title=""><img src="{{getbase_url}}/{{eventImages.event_image}}" class="img-fluid"></a>

                        <a class="delete-icon" title="Delete" (click)="openDeleteModal(deletetemplate, eventImages)"><i class="material-icons">clear</i></a>
                      </div>
                    </div>
                  </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!------------------------------------------------------ ADD  MODAL -------------------------------------------------------->
 
<ng-template #addtemplate>
    <div class="modal-header">
        <h4 class="modal-title">ADD EVENT PHOTO</h4>
        <button type="button" class="close" aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
   
    <div class="modal-body EventModel">
        <!-- *ngIf="progessBar==true" -->
        <div class="row mb-4" *ngIf="progessBar==true">
        <div class="col-sm">
            <!-- <mat-progress-bar mode="indeterminate" value="20" bufferValue='100'></mat-progress-bar> -->
        </div>
        <div class="progressMessage">Please wait until all images compresse</div>
        </div>
        <div class="row mb-4" *ngIf="uploadingProgess==true">
        <div class="col-sm">
            <!-- <mat-progress-bar mode="indeterminate" value="20" bufferValue='100'></mat-progress-bar> -->
        </div>
        <div class="progressMessage">Please wait until all images upload</div>
        </div>
        <div class="row">
        <div class="col-sm">
            <input class="input-file basic-addon2" value="" placeholder="Select Image" type="file" multiple (change)="handleImageUpload($event)" required style="width:100%;height:28px;z-index:10" >
        </div>
        </div>
    </div>
    <div class="modal-footer">                        
        <button class="btn footer-btn bg-info" (click)="addEventImages()" >Upload</button>
        <button class="btn footer-btn bg-secondary" (click)="modalRef.hide()">cancel</button>
    </div>
</ng-template>

<!----------------------------------------------------- DELETE  MODAL -------------------------------------------------------->

    <ng-template #deletetemplate>
        <div class="modal-header">
            <h4 class="modal-title">CONFIRMATION</h4>
            <button type="button" class="close" aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
        </div>
        <div class="modal-body">
            <h1 style="font-size: 18px;">Are You Sure to Delete This Image ?</h1>
        </div>
        <!--Footer-->
        <div class="modal-footer">                        
            <button class="btn footer-btn bg-info"  (click)="deleteEventImagedone(deleteData)">YES</button>
            <button class="btn footer-btn bg-secondary" (click)="modalRef.hide()">NO</button>
        </div>
        <!-- </div> -->
    </ng-template>
<!-- </div> -->
</div>

