import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-facilitator-dtls',
  templateUrl: './facilitator-dtls.component.html',
  styleUrls: ['./facilitator-dtls.component.scss']
})
export class FacilitatorDtlsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
