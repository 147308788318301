import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-talking-your-bodymind',
  templateUrl: './talking-your-bodymind.component.html',
  styleUrls: ['./talking-your-bodymind.component.scss']
})
export class TalkingYourBodymindComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
