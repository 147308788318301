<section class="event-section section-padding" id="pastEvent">
    <div class="container-fluid">
        <div class="section-title text-center">
            <h2>Past event</h2>
            <img src="assets/img/bottom-line.png" class="bottom-line">
        </div>

        <owl-carousel-o [options]="customOptions">
            <ng-template carouselSlide *ngFor="let pastevent of getpasteventall; let i = index" [id]="pastevent.id">
                <div class="gallery-box effect1">
                    <img  [src]="pastevent.image? imgbaseurl+pastevent.image : ''" class="img-fluid img-set"/>                 
                    <a data-toggle="modal" (click)="openEditModal(edittemplate, pastevent)" class="popup-btn"><i class="fa fa-search" aria-hidden="true"></i></a>
                </div>
            </ng-template>  
         </owl-carousel-o>

    </div>
</section>

<!----------------------------------------------------- PAST EVENT SINGLE DATA -------------------------------------------------------->

<ng-template #edittemplate>
    <div class="modal-header">
      <h5 class="modal-title">PAST EVENT</h5>
      <button type="button" class="close" aria-label="Close" (click)="modalRef.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="slider-form">
        <div class="detail">
            <h4>{{title}}</h4>
            <h4>Event Dates  :  {{startdate| date:'dd MMM yyyy'}} - {{enddate| date:'dd MMM yyyy'}} </h4>
            <p>{{description}}</p>
        </div>
        <owl-carousel-o [options]="customOptionsevent">
            <ng-template carouselSlide *ngFor="let event of getsingledata.eventdetail; let i = index" [id]="event.id">
                <div class="gallery-boxevent effect1">
                    <img  [src]="event.event_image? imgbaseurl+event.event_image : ''" class="img-fluid img-set"/>
                </div>
            </ng-template>  
        </owl-carousel-o>
        
        <h4 *ngIf="getsingledata == ''">You don't have Past event Images .</h4>
        </div>
  </div>
</ng-template>
