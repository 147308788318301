import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './shared/component/home/home.component';
import { CommonModule, } from '@angular/common';
import { BrowserModule  } from '@angular/platform-browser';

import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { FacilitatorDtlsComponent } from './shared/component/home/our-facilitators/facilitator-dtls/facilitator-dtls.component';
import { OshoActiveMeditationsComponent } from './shared/component/osho-active-meditations/osho-active-meditations.component';
import { OshoDetailsComponent } from './shared/component/osho-details/osho-details.component';

import { LoginpageComponent } from './shared/component/loginpage/loginpage.component';
import { DevendraComponent } from './shared/component/home/our-facilitators/facilitator-dtls/devendra/devendra.component';
import { AnilComponent } from './shared/component/home/our-facilitators/facilitator-dtls/anil/anil.component';
import { PremSadhanaComponent } from './shared/component/home/our-facilitators/facilitator-dtls/prem-sadhana/prem-sadhana.component';
import { SindhuPremComponent } from './shared/component/home/our-facilitators/facilitator-dtls/sindhu-prem/sindhu-prem.component';
import { DhyanRajivComponent } from './shared/component/home/our-facilitators/facilitator-dtls/dhyan-rajiv/dhyan-rajiv.component';
import { KundaliniMeditationComponent } from './shared/component/osho-active-meditations/kundalini-meditation/kundalini-meditation.component';
import { NadabrahmaMeditationComponent } from './shared/component/osho-active-meditations/nadabrahma-meditation/nadabrahma-meditation.component';
import { NatarajMeditationComponent } from './shared/component/osho-active-meditations/nataraj-meditation/nataraj-meditation.component';
import { DevavaniMeditationComponent } from './shared/component/osho-active-meditations/devavani-meditation/devavani-meditation.component';
import { GourishankarMeditationComponent } from './shared/component/osho-active-meditations/gourishankar-meditation/gourishankar-meditation.component';
import { MandalaMeditationComponent } from './shared/component/osho-active-meditations/mandala-meditation/mandala-meditation.component';
import { WhirlingMeditationComponent } from './shared/component/osho-active-meditations/whirling-meditation/whirling-meditation.component';
import { NoDimensionsMeditationComponent } from './shared/component/osho-active-meditations/no-dimensions-meditation/no-dimensions-meditation.component';
import { ChakraSoundsMeditationComponent } from './shared/component/osho-active-meditations/chakra-sounds-meditation/chakra-sounds-meditation.component';
import { ChakraBreathingMeditationComponent } from './shared/component/osho-active-meditations/chakra-breathing-meditation/chakra-breathing-meditation.component';
import { OshoMysticRoseComponent } from './shared/component/osho-active-meditations/osho-mystic-rose/osho-mystic-rose.component';
import { OshoBornAgainComponent } from './shared/component/osho-active-meditations/osho-born-again/osho-born-again.component';
import { NoMindComponent } from './shared/component/osho-active-meditations/no-mind/no-mind.component';
import { TalkingYourBodymindComponent } from './shared/component/osho-active-meditations/talking-your-bodymind/talking-your-bodymind.component';

import { FacilitiesComponent } from './shared/component/home/facilities/facilities.component';

const routes: Routes = [
  { path:'', component:HomeComponent },
  { path:'home', component:HomeComponent },
  { path:'member-details', component:FacilitatorDtlsComponent },
  { path:'devendra', component:DevendraComponent },
  { path:'anil', component:AnilComponent },
  { path:'prem-sadhana', component:PremSadhanaComponent },
  { path:'sindhu-prem', component:SindhuPremComponent },
  { path:'dhyan-rajiv', component:DhyanRajivComponent },

  // Home Header RouterLink ------------------------
  
  { path:'facilities', component:FacilitiesComponent },

  { path:'osho-active-meditation', component:OshoActiveMeditationsComponent },
  { path:'kundalini-meditation', component:KundaliniMeditationComponent },
  { path:'nadabrahma-meditation', component:NadabrahmaMeditationComponent },
  { path:'nataraj-meditation', component:NatarajMeditationComponent },
  { path:'devavani-meditation', component:DevavaniMeditationComponent },
  { path:'gourishankar-meditation', component:GourishankarMeditationComponent },
  { path:'mandala-meditation', component:MandalaMeditationComponent },
  { path:'whirling-meditation', component:WhirlingMeditationComponent }, 
  { path:'no-dimensions-meditation', component:NoDimensionsMeditationComponent },
  { path:'chakra-sounds-meditation', component:ChakraSoundsMeditationComponent },
  { path:'chakra-breathing-meditation', component:ChakraBreathingMeditationComponent },
  { path:'osho-mystic-rose', component:OshoMysticRoseComponent },
  { path:'osho-born-again', component:OshoBornAgainComponent },
  { path:'osho-no-mind', component:NoMindComponent },
  { path:'talking-your-bodymind', component:TalkingYourBodymindComponent },

  { path:'about', component:OshoDetailsComponent },
  { path:'Loginpage', component:LoginpageComponent },
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full',
  },
  {
    path: '',
    component: AdminLayoutComponent,
    children: [{
      path: '',
      loadChildren: () => import('./layouts/admin-layout/admin-layout.module').then(m => m.AdminLayoutModule)
    }]
  },
  { path:'member-details', component:FacilitatorDtlsComponent }
];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes,{
      //  useHash: true
    })
  ],
  exports: [
  ],
})
export class AppRoutingModule { }
