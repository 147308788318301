<header id="header" class="header clearfix fixed-top">
    <div class="container-fluid container-set">
    <div class="header-wrap clearfix">
        <nav class="navbar navbar-expand-lg">
            <a class="navbar-brand"  href="#homepage"  routerLinkActive="active">
                <img src="assets/img/logo.png" class="logo">
            </a>
           
             <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <span class="navbar-toggler-icon"><i class="fa fa-bars" aria-hidden="true"></i></span>
            </button>
          
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
              <ul class="navbar-nav ml-auto">
                <li class="nav-item">
                  <a class="nav-link js-scroll-trigger" href="#homepage"  routerLinkActive="active">Osho Manan</a>
                </li>
                <li class="nav-item dropdown">
                  <a class="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    Osho Active Meditations
                  </a>
                  <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                    <a class="dropdown-item" routerLink="/osho-active-meditation" routerLinkActive="active">OSHO Dynamic Meditation</a>
                    <a class="dropdown-item" routerLink="/kundalini-meditation">OSHO Kundalini Meditation</a>
                    <a class="dropdown-item" routerLink="/nadabrahma-meditation">OSHO Nadabrahma Meditation</a>
                    <a class="dropdown-item" routerLink="/nataraj-meditation">OSHO Nataraj Meditation</a>
                    <a class="dropdown-item" routerLink="/devavani-meditation">OSHO Devavani Meditation</a>
                    <a class="dropdown-item" routerLink="/gourishankar-meditation">OSHO Gourishankar Meditation</a>
                    <a class="dropdown-item" routerLink="/mandala-meditation">OSHO Mandala Meditation</a>
                    <a class="dropdown-item" routerLink="/whirling-meditation">OSHO Whirling Meditation</a>
                    <a class="dropdown-item" routerLink="/no-dimensions-meditation">OSHO No-Dimensions Meditation</a>
                    <a class="dropdown-item" routerLink="/chakra-sounds-meditation">OSHO Chakra Sounds Meditation</a>
                    <a class="dropdown-item" routerLink="/chakra-breathing-meditation">OSHO Chakra Breathing Meditation</a>
                    <div class="dropdown-divider"></div>
                    <a class="dropdown-item"><b>Meditative Therapies</b></a>
                    <a class="dropdown-item" routerLink="/osho-mystic-rose">OSHO Mystic Rose</a>
                    <a class="dropdown-item" routerLink="/osho-born-again">OSHO Born Again</a>
                    <a class="dropdown-item" routerLink="/osho-no-mind">OSHO No-Mind</a>
                    <a class="dropdown-item" routerLink="/talking-your-bodymind">OSHO Talking To Your BodyMind</a>
                  </div>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="#facilities" routerLinkActive="active">Facilities</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="#pastEvent" routerLinkActive="active">Past Event</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="#calendar" routerLinkActive="active">Calendar</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="#contact"  routerLinkActive="active">Contact Us</a>
                </li> 
                <!-- <li class="nav-item">
                  <a class="nav-link" routerLink="/Loginpage"  routerLinkActive="active">LOGIN</a>
              </li> -->
              </ul>
            </div>
          </nav>
          
    </div> <!--/.header-wrap -->
    </div>
</header>

<!--==== Fixed Icon ====-->
<div class="social-icon">
  <ul>
    <li class="faceBook"><a href="https://www.facebook.com/OshoManan" title="Facebook" target="_blank"><i class="fa fa-facebook" aria-hidden="true"></i></a></li>
    <li class="youTube"><a href="https://www.youtube.com/channel/UCEkiTfU3L96LowzUhnOgKEA" title="youTube" target="_blank"><i class="fa fa-youtube-play" aria-hidden="true"></i></a></li>
  </ul>
</div>
<!--==== Fixed Icon ====-->

                         