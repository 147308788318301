<section class="about-osho section-padding">
    <div class="container-fluid container-set">
        <div class="row">
            <div class="col-12 col-sm-9 about-text">
                <div class="section-title">
                    <h2>OSHO MANAN</h2>
                    <img src="assets/img/bottom-line.png" class="bottom-line">
                </div>

                <h4>OSHO Manan Neo-Sannyas Commune</h4>
                <p>'OSHO' is a new beginning in the stream of Enlightenment. By presenting vision of new man ' Zorba The Buddha' to the world he started new path where Science & Religion merge together first time.</p>
                <p>
                    During late 60's a rebellious voice emerged in the Spiritual World namely 'Acharya Shree Rajneesh' and was spread like forest fire in India .
                </p>
                <p>
                    People of India were influenced through his discourses and those who were seeking for truth started to gather around him from rest of the world.
                </p>
                <p>
                    In 1970, OSHO resided in Mumbai. During this period from every part of Gujarat,seekers started to take interest in Osho Discourses. They invited Osho for conducting meditation camps & discourse series. They were influenced by his exceptional clarity and deep thinking about truth and many of them joined in the flow of rebellious consciousness.
                </p>

                <a routerLink="/about" class="theme-btn">Read More</a>
                
            </div>
            <div class="col-12 col-sm-3">
                <img src="assets/img/osho.png" class="img-fluid osho-img">
            </div>
        </div>
    </div>
</section>
<!-- about-osho -->