<app-header></app-header>
<!-- ==== Header ==== -->
    <app-page-title heading1="OSHO DEVAVANI MEDITATION" heading2="Osho Devavani Meditation"></app-page-title>

    <section class="section-padding">
        <div class="container-fluid">
          <div class="row">
            <div class="col-sm-12">
            <div class="meditation-box">
              <img src="assets/img/Osho-Nataraj.jpg" class="img-fluid">
              <p>The meditation lasts one hour and has four stages of 15 minutes each. Keep your eyes closed throughout.</p>

              <p>In this meditation a gentle, unfamiliar language moves and speaks through the meditator, who becomes an empty vessel.</p>

              <p>
                It deeply relaxes the mind and creates inner peace. It can be done at any time of the day. If done last thing at night, it also creates a profound sleep.
              </p>

              <p>
                <strong>First Stage: 15 minutes</strong>
                Sit quietly, while the music is playing.
              </p>

              <p>
                  <strong>Second Stage: 15 minutes</strong>
                  Start making nonsense sounds, for example “la la la” and continue until unfamiliar word-like sounds arise. These sounds need to come from the unfamiliar part of the brain used as a child, before words were learned. Allow a gentle conversational intonation; do not cry or shout, laugh or scream.
              </p>

              <p>
                <strong>Third Stage: 15 minutes</strong>
                Stand up and continue this unfamiliar language, allowing your body to move softly in harmony with the sound. If your body is relaxed, the subtle energies will create a Latihan – a spontaneous, unstructured movement outside your control.
              </p>

                <p>
                    <strong>Fourth Stage: 15 minutes</strong>
                    Lie down. Be silent and still.
                </p>

            </div>
            </div>
          </div>
        </div>
    </section>


<!-- ==== Footer ==== -->
<app-front-end-footer></app-front-end-footer>