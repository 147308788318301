import { Component, OnInit, TemplateRef } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { HomepagesliderService } from './../../../../Services/homepageslider.service';
import { MessagePanelService } from './../../../../Services/message-panel.service';
import { Globals } from './../../../../global';
import { LocalStorageService } from './../../../../Services/local-storage.service';
import { Router } from '@angular/router';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-upcoming-event',
  templateUrl: './upcoming-event.component.html',
  styleUrls: ['./upcoming-event.component.scss']
})
export class UpcomingEventComponent implements OnInit {

  
  modalRef: BsModalRef;
  public data : any;
  getcalenderall:any =[];
  imgbaseurl="";

  constructor(public globals: Globals, private router: Router, private formBuilder: FormBuilder, private messagePanelService: MessagePanelService, private localStorageService: LocalStorageService, public homepagesliderService:HomepagesliderService,private modalService: BsModalService) { }

  ngOnInit(): void {    
    this.imgbaseurl=this.localStorageService.getBaseUrl();
    this.geteventalldata();
  }

  geteventalldata(){
    console.log("get All CALENDER");
    this.homepagesliderService.getcalender().subscribe(data => {
      if (data === null || data === undefined) {
        this.messagePanelService.ShowPopupMessageWithLocalization('An error occured, please try again later', this.globals.messageCloseTime, this.globals.messageType.error);
      } else if (data['success'] === true) {
        if(data.Event.length>0){
          this.getcalenderall = data.Event;
          console.log("GET EVENT DATA ALL________",this.getcalenderall);
        }else{
          this.getcalenderall = data.Event;
          this.messagePanelService.ShowPopupMessageWithLocalization(data['message'], this.globals.messageCloseTime, this.globals.messageType.success);
        }
      } else if (data['statusCode'] == 400) {
        this.messagePanelService.ShowPopupMessageWithLocalization(data['message'], this.globals.messageCloseTime, this.globals.messageType.success);
      } else {
        this.messagePanelService.ShowPopupMessageWithLocalization(data['message'], this.globals.messageCloseTime, this.globals.messageType.error);
      }
    });
  }

}
