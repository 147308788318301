import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-nadabrahma-meditation',
  templateUrl: './nadabrahma-meditation.component.html',
  styleUrls: ['./nadabrahma-meditation.component.scss']
})
export class NadabrahmaMeditationComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
