<app-header></app-header>
<!-- ==== Header ==== -->
    <app-page-title heading1="OSHO BORN AGAIN" heading2="Osho Born Again"></app-page-title>

    <section class="section-padding">
        <div class="container-fluid">
          <div class="row">
            <div class="col-sm-12">
            <div class="meditation-box">
              <img src="assets/img/no-mind.jpg" class="img-fluid">
              <p>
                This is a seven-day course, which includes one hour of Gibberish and one hour of silent sitting together as a group from about 10am to 12am. It also includes Osho Dynamic Meditation at sunrise, Osho Kundalini Meditation at sunset, followed by the Osho Evening Meeting.</p>

              <p class="pl-3">
               <i>“I am dealing with the contemporary man, who is the most restless being that has ever evolved on the earth. But people do become silent; you just have to allow them to throw out their madness, insanity, then they themselves become silent.”Osho</i>
              </p>

              <p>
                <strong>GIBBERISH</strong>
              </p>

              <p>
                In that first hour of the Multiversity course, we have the perfect pportunity to throw out the gibberish of the mind. As Osho explains, “Gibberish is one of the most scientific ways to clean your mind”. And also that:
              </p>

              <p>
                  <i>
                    “To be in the mind is to be out of yourself.<br>
                    To be out of the mind is to be in your own being.”
                  </i>
              </p>

            <p>
                <i>
                    “Just go inwards and it is not thousands of miles away. It is only thousands of thoughts away. 
                </i>
            </p>

            <p>
                <i>
                    “So it is only a question of reducing the thoughts and the mileage is reduced.<br>
If you don't have any thoughts, you are in it. You are it.
                </i>
            </p>

            <p>
                <i>
                    “You don't have to go anywhere; you have to simply go on throwing your thoughts.
                </i>
            </p>

            <p>
                <i>
                    “That is the purpose of our gibberish before the meditation. Just a time to throw everything, without inhibition. These creatures that you throw out are eating your head continuously, destroying your intelligence. Catch them and throw them out.”
                </i>
            </p>

            <p>
                <strong>What Is Gibberish?</strong>
                Just say everything that you ever wanted to say and have not been able to say because of civilization, education, culture, and society. Be very passionate, as if you are talking, as if your whole life is at stake. You are talking nonsense and there is nobody except you, but be passionate, be in a passionate dialogue. Saying anything that is moving in your mind, all kinds of rubbish – throw it out.
            </p>

            <p>
                And say it in any language you don’t know! Use Chinese, if you don't know Chinese. Use Japanese if you don't know Japanese. Don't use German if you know German. Shouting, laughing, crying, making noises … making gestures …. Sounds will come – utter them – and one sound will lead into another. Soon you will be speaking Chinese and Italian and French, languages that you don’t know. Simply allow whatever comes to your mind without bothering about its rationality, reasonability, meaning, or significance. For the first time enjoy that freedom -- the same as all the birds have.
            </p>

            <p>
                Do it totally, with great enthusiasm …. Be authentic, honest …. You have to be total. Don’t act or do it automatically like a robot. Be sincere … make it a reality …. So don't be partial, don't be middle-class. Be a first-rate crazy man! Just go crazy without any fear. Don't spare anything. Don't sit there like a Buddha; that stage comes later. Remember, the first step in meditation is to forget the whole world and just bring out all your craziness in rubbish, gibberish sounds, gestures …. As you throw it out you will find yourself becoming light, becoming more alive. But be total because once you are freed of it, then there is a possibility of going deeper into silence than you have ever gone.
            </p>

            <p>As Osho describes it: </p>

            <p class="pl-3">““Put the conscious off and allow the unconscious to speak....” </p>

            <p class="pl-3">“Be very passionate in it, as if you are talking, as if your whole life is at stake. You are talking nonsense and there is nobody except you, but be passionate, be in a passionate dialogue.” </p>

            <p class="pl-3">“You have to be authentic about it. It is not for somebody else, it is for the sky. And you cannot deceive the sky.” </p>

            <p class="pl-3">“It is one of the most ecstatic meditations ever. As you go into it, get more and more ecstatic with it. If your body starts moving, your hands start moving, gestures happen and you feel like standing up or jumping or dancing, allow it. Let your whole body and being say it. And get deeper and deeper into it - be possessed by it.” </p>

            <p class="pl-3">“Out of this will arise the second part, a great silence in which you have to close your eyes and freeze your body, all its movements, gather your energy within yourself.”
            </p>

            <p>
                <strong>SITTING IN SILENCE</strong>
                Just be absolutely silent, gather your whole energy inwards, just like you are coming home.
            </p>

            <p class="pl-3"> <i>
                “Now you are ready to go inwards with all your consciousness, with all your life energy. Move towards the center of your being. That is from where you have come and that is where everybody goes back.
            </i> </p>

            <p class="pl-3"> <i>
                “Deeper and deeper … the deeper you go, the more fragrant becomes the air. The deeper you go, suddenly flowers start showering on you. At the deepest point you are the buddha. The buddha means simply witnessing, pure witnessing. Just witness: the body is there, the mind is there, but you are neither.
            </i> </p>

            <p class="pl-3"> <i>
                “You are a separate force which comes from the center, and the center is joined with eternity. It knows no birth, no death. This buddha is your ultimate potential, the very Everest of consciousness. Great is the splendor of this moment.”</i> Osho
             </p>

            </div>
            </div>
          </div>
        </div>
    </section>


<!-- ==== Footer ==== -->
<app-front-end-footer></app-front-end-footer>