import { Injectable } from '@angular/core';
import { MessageType } from './model/messagetype-model'
@Injectable()
export class Globals {
    role: string = 'test';
    messageCloseTime: number = 3000;
    applicationtype: string = "Web";
    // base_url:string='http://168.235.64.167:8998';
    // base_url: string = 'http://localhost:8181/api/';
    base_url: string = 'https://www.api.oshomanan.teengineers.co.in/';
    messageType: MessageType = {
        success: "success",
        warn: "warn",
        error: "error",
        info: "info"

    };
}