import { Inject, Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { HttpHeaders, HttpErrorResponse, HttpClient } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LoginpageService {


  apiUrl: string;

  constructor(@Inject('API_URL') baseUrl: string,
    private http: HttpClient) {
    this.apiUrl = baseUrl;
  }

  getHeadernonlogin() {
    return {
      headers: new HttpHeaders({
        'authorization': 'abcd',
        'Content-Type': 'application/json'
      }),
      responseType: 'text' as 'json'
    };
  };

  LoginAdmin(body): Observable<any> {
    let endpoint = 'admin/login';
    return this.http.post<any>(this.apiUrl + endpoint, body, this.getHeadernonlogin())
      .pipe(map(res => JSON.parse(res)), catchError(err => this.handleError(err)));
  }


  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof Error) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
      // this.notificationService.setError(error.error.message, false);
    } else {
      if (error.status === 400) {
        if (error.error.validationErrors) {
          error.error.validationErrors.forEach((val, index) => {
            //this.notificationService.setError(val.message);
          });
        } else {
          //  this.notificationService.setError(error.error.message, false);
        }
      } else if (error.status === 500) {
        //this.notificationService.setError('Something bad happed. Please try again.');
      } else {
        // The backend returned an unsuccessful response code.
        // The response body may contain clues as to what went wrong,
        console.log(error);
        if (error.error) {
          //this.notificationService.setError('Something bad happed. Please try again.', false);
        } else {
          //this.notificationService.setError(error.error.error_description, false);
        }
      }
    }
    return throwError('Something bad happed. Please try again.');
  }

}
