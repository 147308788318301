<app-header></app-header>
<!-- ==== Header ==== -->
    <app-page-title heading1="OSHO CHAKRA SOUNDS MEDITATION" heading2="Osho Chakra Sounds Meditation"></app-page-title>

    <section class="section-padding">
        <div class="container-fluid">
          <div class="row">
            <div class="col-sm-12">
            <div class="meditation-box">
              <p>In this meditation vocal sounds open, harmonize and bring awareness to the chakras or energy centers. It can bring you into a deep, peaceful, inner silence. You can make your own vocal sounds, or just listen to the music and feel the sounds within you. </p>

              <p><i>The meditation is to be done with its specific OSHO Chakra Sounds Meditation music, which indicates and energetically supports the different stages.</i></p>

              <div class="row list">
                  <div class="col-sm-12">
                    Chakras lie deep within, rather than on the surface of the body. You will move up and down these seven:
                  </div>
                  <div class="col-12 col-sm-3">
                    <p>1. base chakra:</p>
                    <p>2. sacral chakra:</p>
                    <p>3. solar plexus chakra:</p>
                    <p>4. heart chakra:</p>
                    <p>5. throat chakra:</p>
                    <p>6. third eye chakra:</p>
                    <p>7. crown chakra:</p>
                  </div>
                  <div class="col-12 col-sm-3">
                      <p>the sex center, lower pelvis</p>
                      <p>just below the navel</p>
                      <p>above the navel, below the breastbone</p>
                      <p>the middle of the chest</p>
                      <p>the throat</p>
                      <p>between the eyebrows </p>
                      <p> top of the head</p>
                  </div>
              </div>
             
              <p>
                <strong>Instructions:</strong>
                The meditation lasts one hour and has two stages. It ends when you hear three gongs. Keep your eyes closed throughout.</p>

                <p>
                    <strong>First stage: 45 minutes</strong>
                    Stand, sit comfortably, or lie down, keeping your back straight and your body loose. Breathe into your belly rather than your chest. The sounds should be made with your mouth open and your jaw loose, keeping your mouth open the whole time.
                </p>

                <p>
                    Close your eyes and listen to the music; start with the first chakra and if you wish, start making sounds – in a single tone or varying the tone. While listening to the sound of the music or the sounds that you are making, feel the sounds pulsating in the very center of the chakra. You can use your imagination to get into it, if needed at first – and then feel the inner vibrations of that chakra. </p>
                
                <p>
                    You will hear the tones change to a higher pitch – now listen and feel sounds in the second chakra, and continue making sounds if you wish. This process continues all the way up to the seventh chakra. With each next chakra, let your sounds become higher in pitch. 
                </p>

                <p>
                    After you have reached the seventh chakra, the tones will go back down through all the chakras, one at a time. As you hear the tones go down, listen and make sounds in each chakra. Feel your body become like a hollow bamboo flute, allowing the sounds to resonate from top to bottom. 
                </p>

                <p>
                    At the end of the sequence, you will hear a pause before the next sequence starts. The full sequence up and down is done three times altogether.
                </p>

                <p>
                    <strong>Second stage: 15 minutes</strong>
                    Sit or lie down and be silent. Watch whatever is happening within – relaxed, without any judgment, remaining a witness.
                </p>

            </div>
            </div>
          </div>
        </div>
    </section>


<!-- ==== Footer ==== -->
<app-front-end-footer></app-front-end-footer>