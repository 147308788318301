import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-anil',
  templateUrl: './anil.component.html',
  styleUrls: ['./anil.component.scss']
})
export class AnilComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
