
<section class="fecilities-section section-padding" id="facilities">
  <div class="container-fluid container-set">
      <div class="section-title text-center">
          <h2>FACILITIES</h2>
          <img src="assets/img/bottom-line.png" class="bottom-line">
      </div>
      <h4 class="text-center">Osho Manan Neo-Sannyas Commune is one of the largest communes of India.</h4>
      <p class="text-center">It is surrounded by lush green enviornment which is helpful for a seeker to go deep in meditation.<br>
          It offers a seeker not only a perfect atmosphere for meditation, but a nice full facilited resort also.<br>
          The vision of osho "Life is a celebration" is motto of the commune and which can be smelled <br>
          throughout the commune activities.</p>

          <div class="row facilite-cols">
              <div class="col-12 col-sm-4">
                  <div class="inner-Box">
                      <p>Meditation Facilities</p>
                      <img src="assets/img/camp-groups/Camp-Groups19.jpg" class="img-fluid">
                      <a data-toggle="modal" data-target="#meditation-facilities" class="popup-btn">+</a>
                  </div>
              </div>
              <div class="col-12 col-sm-4">
                  <div class="inner-Box">
                      <p>Zorba The Cafe</p>
                      <img src="assets/img/f-2.jpg" class="img-fluid">
                      <a data-toggle="modal" data-target="#zorba-cafe" class="popup-btn">+</a>
                  </div>
              </div>
              <div class="col-12 col-sm-4">
                  <div class="inner-Box">
                      <p>Work as Meditation</p>
                      <img src="assets/img/work-weditation.jpg" class="img-fluid">
                      <a data-toggle="modal" data-target="#work-meditation" class="popup-btn">+</a>
                  </div>
              </div>
              <div class="col-12 col-sm-4">
                  <div class="inner-Box">
                      <p>Natural Environment</p>
                      <img src="assets/img/f-4.jpg" class="img-fluid">
                      <a data-toggle="modal" data-target="#natural-environment" class="popup-btn">+</a>
                  </div>
              </div>
              <div class="col-12 col-sm-4">
                  <div class="inner-Box">
                      <p>Accomodations</p>
                      <img src="assets/img/f-5.jpg" class="img-fluid">
                      <a data-toggle="modal" data-target="#accomodations" class="popup-btn">+</a>
                  </div>
              </div>
              <div class="col-12 col-sm-4">
                  <div class="inner-Box">
                      <p>Parties & Events</p>
                      <img src="assets/img/f-6.jpg" class="img-fluid">
                      <a data-toggle="modal" data-target="#parties-events" class="popup-btn">+</a>
                  </div>
              </div>
              <div class="col-12 col-sm-4">
                  <div class="inner-Box">
                      <p>Food</p>
                      <img src="assets/img/f-7.jpg" class="img-fluid">
                      <a data-toggle="modal" data-target="#food" class="popup-btn">+</a>
                  </div>
              </div>
              <div class="col-12 col-sm-4">
                  <div class="inner-Box">
                      <p>Camps & Groups</p>
                      <img src="assets/img/f-8.jpg" class="img-fluid">
                      <a data-toggle="modal" data-target="#camps-groups" class="popup-btn">+</a>
                  </div>
              </div>
              <div class="col-12 col-sm-4">
                  <div class="inner-Box">
                      <p>OSHO Book Shop</p>
                      <img src="assets/img/f-9.jpg" class="img-fluid">
                      <a data-toggle="modal" data-target="#book-shop" class="popup-btn">+</a>
                  </div>
              </div>
          </div>  <!--facilite-cols-->
  </div>
</section>


<!-- Modal 01 -->
<div class="modal fade" id="meditation-facilities" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Meditation Facilities</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <h4>OSHO Says:</h4>
        <p>Meditaition is an adventure, an adventure into the unknown, the greatest adventure the human mind can take. Meditation is just to be, not doing anything - no action, no thought, no emotion. You just are and it is a sheer delight. From where does this delight come when you are not doing anything? It comes from nowhere, or it comes from everywhere. It is uncaused, because existence is made of the stuff called joy.</p>
        <h4>Meditation Facilities at OMNS Commune:</h4>
        <p>Osho Manan Commune has been developed only for meditation purpose, where Osho Meditation techniques like Dynamic, Nadbrahma, Nataraj, Kundalini, Evening Satsang, No-Dimension, Gaurishankar, Mandala, Whirling , more meditation methods & OSHO Meditative Therapies - No-Mind, Mystic Rose, Born Again are practiced. Besides Osho discourses are played on tape and video screen. For this, commune has Open and Closed Meditation Halls.</p>
        <h4>Main Indoor Meditation Hall:</h4>
        <div class="row">
            <div class="col-6 col-sm-3">
                <img src="assets/img/mf-1.jfif" class="img-fluid">
            </div>
            <div class="col-6 col-sm-3">
              <img src="assets/img/mf-2.jfif" class="img-fluid">
          </div>
          <div class="col-6 col-sm-3">
              <img src="assets/img/mf-3.jfif" class="img-fluid">
          </div>
          <div class="col-6 col-sm-3">
              <img src="assets/img/mf-4.jfif" class="img-fluid">
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<!-- Modal 02 -->
<div class="modal fade" id="zorba-cafe" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Zorba The Cafe</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <h4>Zorba-Gala parties are being organized during the nights of Meditation Camps & in events.</h4>
        <div class="row">
            <div class="col-6 col-sm-4">
                <img src="assets/img/zorba/zorba1.jpg" class="img-fluid">
            </div>
            <div class="col-6 col-sm-4">
              <img src="assets/img/zorba/zorba2.jpg" class="img-fluid">
          </div>
          <div class="col-6 col-sm-4">
              <img src="assets/img/zorba/zorba3.jpg" class="img-fluid">
          </div>
          <div class="col-6 col-sm-4">
              <img src="assets/img/zorba/zorba4.jpg" class="img-fluid">
          </div>
          <div class="col-6 col-sm-4">
              <img src="assets/img/zorba/zorba5.jpg" class="img-fluid">
          </div>
          <!-- <div class="col-6 col-sm-4">
            <img src="assets/img/zorba/zorba6.jpg" class="img-fluid">
          </div>
          <div class="col-6 col-sm-4">
            <img src="assets/img/zorba/zorba7.jpg" class="img-fluid">
          </div> -->
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Modal 03 -->
<div class="modal fade" id="work-meditation" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Work as Meditation</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <p>Various Work As Meditation Programmes are run by the commune.
          For more information about workshops one has to send his personal details. Do visit the commune for Work as Meditation programme.</p>
        <div class="row">
            <div class="col-6 col-sm-4">
                <img src="assets/img/work-01.jpg" class="img-fluid">
            </div>
            <div class="col-6 col-sm-4">
              <img src="assets/img/work-02.jpg" class="img-fluid">
          </div>
          <div class="col-6 col-sm-4">
              <img src="assets/img/work-03.jpg" class="img-fluid">
          </div>
          <div class="col-6 col-sm-4">
              <img src="assets/img/work-04.jpg" class="img-fluid">
          </div>
          <div class="col-6 col-sm-4">
              <img src="assets/img/work-05.jpg" class="img-fluid">
          </div>
          <div class="col-6 col-sm-4">
            <img src="assets/img/work-06.jpg" class="img-fluid">
        </div>
        <div class="col-6 col-sm-4">
            <img src="assets/img/work-07.jpg" class="img-fluid">
        </div>
        <div class="col-6 col-sm-4">
            <img src="assets/img/work-08.jpg" class="img-fluid">
        </div>
        <div class="col-6 col-sm-4">
          <img src="assets/img/work-09.jpg" class="img-fluid">
        </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Modal 04 -->
<div class="modal fade" id="natural-environment" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Natural Environment</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <h4>OMNSC is situated out of City though so it is in non-poluted area and surrounded by Lush Green Trees and beautiful views.</h4>
        <div class="row">
          <div class="col-6 col-sm-4">
            <img src="assets/img/campus/campus1.jpg" class="img-fluid">
          </div>
          <div class="col-6 col-sm-4">
              <img src="assets/img/campus/campus2.jpg" class="img-fluid">
          </div>
          <div class="col-6 col-sm-4">
              <img src="assets/img/campus/campus3.jpg" class="img-fluid">
          </div>
          <div class="col-6 col-sm-4">
              <img src="assets/img/campus/campus4.jpg" class="img-fluid">
          </div>
          <div class="col-6 col-sm-4">
              <img src="assets/img/campus/campus5.jpg" class="img-fluid">
          </div>
          <div class="col-6 col-sm-4">
              <img src="assets/img/campus/campus6.jpg" class="img-fluid">
          </div>
          <div class="col-6 col-sm-4">
              <img src="assets/img/campus/campus7.jpg" class="img-fluid">
          </div>
          <div class="col-6 col-sm-4">
              <img src="assets/img/campus/campus8.jpg" class="img-fluid">
          </div>
          <div class="col-6 col-sm-4">
              <img src="assets/img/campus/campus9.jpg" class="img-fluid">
          </div>
          <div class="col-6 col-sm-4">
              <img src="assets/img/campus/campus10.jpg" class="img-fluid">
          </div>
          <div class="col-6 col-sm-4">
              <img src="assets/img/campus/campus11.jpg" class="img-fluid">
          </div>
          <div class="col-6 col-sm-4">
              <img src="assets/img/campus/campus12.jpg" class="img-fluid">
          </div>
          <div class="col-6 col-sm-4">
            <img src="assets/img/campus/campus13.jpg" class="img-fluid">
          </div>
          <div class="col-6 col-sm-4">
            <img src="assets/img/campus/campus14.jpg" class="img-fluid">
          </div>
          <div class="col-6 col-sm-4">
            <img src="assets/img/campus/campus15.jpg" class="img-fluid">
          </div>
          <div class="col-6 col-sm-4">
            <img src="assets/img/campus/campus16.jpg" class="img-fluid">
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Modal 05 -->
<div class="modal fade" id="accomodations" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Accomodations</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <p>The Commune provides 5 different types of clean, decent and furnished accommodations and healthy, nourishing, hygienic and purely vegetarian food, suited to the needs of a seeker.</p>
        <p>Double Bed - Semi Rooms with shared toilet-bathroom.</p>
        <p>Krishna House Rooms- None A.C. double bed rooms.</p>
        <p>Krishna House A.C. Deluxe Suite.</p>
        <p>Dormitory for 8 persons with attached toilet-bathroom.</p>
        <p>Big dormitory for 15 persons ( Family dormitory for upto 5 persons)</p>
        <div class="row">
            <div class="col-6 col-sm-4">
                <img src="assets/img/rooms/room1.jpg" class="img-fluid">
            </div>
            <div class="col-6 col-sm-4">
              <img src="assets/img/rooms/room2.jpg" class="img-fluid">
          </div>
          <div class="col-6 col-sm-4">
              <img src="assets/img/rooms/room3.jpg" class="img-fluid">
          </div>
          <div class="col-6 col-sm-4">
              <img src="assets/img/rooms/room4.jpg" class="img-fluid">
          </div>
          <div class="col-6 col-sm-4">
              <img src="assets/img/rooms/room5.jpg" class="img-fluid">
          </div>
          <div class="col-6 col-sm-4">
              <img src="assets/img/rooms/room6.jpg" class="img-fluid">
          </div>
          <div class="col-6 col-sm-4">
              <img src="assets/img/rooms/room7.jpg" class="img-fluid">
          </div>
          <div class="col-6 col-sm-4">
              <img src="assets/img/rooms/room8.jpg" class="img-fluid">
          </div>
          <div class="col-6 col-sm-4">
              <img src="assets/img/rooms/room9.jpg" class="img-fluid">
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Modal 06 -->
<div class="modal fade" id="parties-events" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Parties & Events</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <p>During Meditation Camps and Special Events like Osho's Birthday, Enlightend Day, Mahaparinirvan Day commune celebrates with Meditations, Parties, & some classic events are organised.</p>
        <div class="row">
          <div class="col-6 col-sm-4">
              <img src="assets/img/parties-events/Event1.jpg" class="img-fluid">
          </div>
          <div class="col-6 col-sm-4">
              <img src="assets/img/parties-events/Event2.jpg" class="img-fluid">
          </div>
          <div class="col-6 col-sm-4">
              <img src="assets/img/parties-events/Event3.jpg" class="img-fluid">
          </div>
          <div class="col-6 col-sm-4">
              <img src="assets/img/parties-events/Event4.jpg" class="img-fluid">
          </div>
          <div class="col-6 col-sm-4">
              <img src="assets/img/parties-events/Event5.jpg" class="img-fluid">
          </div>
          <div class="col-6 col-sm-4">
              <img src="assets/img/parties-events/Event6.jpg" class="img-fluid">
          </div>
          <div class="col-6 col-sm-4">
              <img src="assets/img/parties-events/Event7.jpg" class="img-fluid">
          </div>
          <div class="col-6 col-sm-4">
              <img src="assets/img/parties-events/Event8.jpg" class="img-fluid">
          </div>
          <div class="col-6 col-sm-4">
              <img src="assets/img/parties-events/Event9.jpg" class="img-fluid">
          </div>
          <div class="col-6 col-sm-4">
            <img src="assets/img/parties-events/Event10.jpg" class="img-fluid">
          </div>
          <div class="col-6 col-sm-4">
            <img src="assets/img/parties-events/Event11.jpg" class="img-fluid">
          </div>
          <div class="col-6 col-sm-4">
            <img src="assets/img/parties-events/Event12.jpg" class="img-fluid">
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Modal 07 -->
<div class="modal fade" id="food" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Food</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <p>Along with accommodations and healthy, nourishing, hygienic and purely vegetarian food, suited to the needs of a seeker.</p>
        <div class="row">
          <div class="col-6 col-sm-6">
              <img src="assets/img/Food-01.jpg" class="img-fluid">
          </div>
          <div class="col-6 col-sm-6">
              <img src="assets/img/Food-02.jpg" class="img-fluid">
          </div>
          <div class="col-6 col-sm-6">
              <img src="assets/img/Food-03.jpg" class="img-fluid">
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Modal 08 -->
<div class="modal fade" id="camps-groups" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Camps & Groups</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-6 col-sm-4">
              <img src="assets/img/camp-groups/Camp-Groups2.jpg" class="img-fluid">
          </div>
          <div class="col-6 col-sm-4">
              <img src="assets/img/camp-groups/Camp-Groups3.jpg" class="img-fluid">
          </div>
          <div class="col-6 col-sm-4">
              <img src="assets/img/camp-groups/Camp-Groups4.jpg" class="img-fluid">
          </div>
          <div class="col-6 col-sm-4">
              <img src="assets/img/camp-groups/Camp-Groups5.jpg" class="img-fluid">
          </div>
          <div class="col-6 col-sm-4">
              <img src="assets/img/camp-groups/Camp-Groups6.jpg" class="img-fluid">
          </div>
          <div class="col-6 col-sm-4">
              <img src="assets/img/camp-groups/Camp-Groups7.jpg" class="img-fluid">
          </div>
          <div class="col-6 col-sm-4">
              <img src="assets/img/camp-groups/Camp-Groups8.jpg" class="img-fluid">
          </div>
          <div class="col-6 col-sm-4">
              <img src="assets/img/camp-groups/Camp-Groups9.jpg" class="img-fluid">
          </div>
          <div class="col-6 col-sm-4">
              <img src="assets/img/camp-groups/Camp-Groups10.jpg" class="img-fluid">
          </div>
          <div class="col-6 col-sm-4">
              <img src="assets/img/camp-groups/Camp-Groups11.jpg" class="img-fluid">
          </div>
          <div class="col-6 col-sm-4">
              <img src="assets/img/camp-groups/Camp-Groups12.jpg" class="img-fluid">
          </div>
          <div class="col-6 col-sm-4">
              <img src="assets/img/camp-groups/Camp-Groups13.jpg" class="img-fluid">
          </div>
          <div class="col-6 col-sm-4">
            <img src="assets/img/camp-groups/Camp-Groups14.jpg" class="img-fluid">
          </div>
          <div class="col-6 col-sm-4">
            <img src="assets/img/camp-groups/Camp-Groups15.jpg" class="img-fluid">
          </div>
          <div class="col-6 col-sm-4">
            <img src="assets/img/camp-groups/Camp-Groups16.jpg" class="img-fluid">
          </div>
          <div class="col-6 col-sm-4">
            <img src="assets/img/camp-groups/Camp-Groups17.jpg" class="img-fluid">
          </div>
          <div class="col-6 col-sm-4">
            <img src="assets/img/camp-groups/Camp-Groups18.jpg" class="img-fluid">
          </div>
          <div class="col-6 col-sm-4">
            <img src="assets/img/camp-groups/Camp-Groups19.jpg" class="img-fluid">
          </div>
          <div class="col-6 col-sm-4">
            <img src="assets/img/camp-groups/Camp-Groups20.jpg" class="img-fluid">
          </div>
          <div class="col-6 col-sm-4">
            <img src="assets/img/camp-groups/Camp-Groups21.jpg" class="img-fluid">
          </div>
          <div class="col-6 col-sm-4">
            <img src="assets/img/camp-groups/Camp-Groups22.jpg" class="img-fluid">
          </div>
          <div class="col-6 col-sm-4">
            <img src="assets/img/camp-groups/Camp-Groups23.jpg" class="img-fluid">
          </div>
          <div class="col-6 col-sm-4">
            <img src="assets/img/camp-groups/Camp-Groups24.jpg" class="img-fluid">
          </div>
          <div class="col-6 col-sm-4">
            <img src="assets/img/camp-groups/Camp-Groups25.jpg" class="img-fluid">
          </div>
          <div class="col-6 col-sm-4">
            <img src="assets/img/camp-groups/Camp-Groups26.jpg" class="img-fluid">
          </div>
          <div class="col-6 col-sm-4">
            <img src="assets/img/camp-groups/Camp-Groups27.jpg" class="img-fluid">
          </div>
          <div class="col-6 col-sm-4">
            <img src="assets/img/camp-groups/Camp-Groups28.jpg" class="img-fluid">
          </div>
          <div class="col-6 col-sm-4">
            <img src="assets/img/camp-groups/Camp-Groups29.jpg" class="img-fluid">
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Modal 09 -->
<div class="modal fade" id="book-shop" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">OSHO Book Shop</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <p>Osho's 9000 hrs. talks are saved in Audio & Video Tapes. From this tapes more then 650 Osho's English & Hindi books are released and some are still realeasing.</p>
        <div class="row">
          <div class="col-6 col-sm-4">
              <img src="assets/img/book-shop/bookshop1.jpg" class="img-fluid">
          </div>
          <div class="col-6 col-sm-4">
              <img src="assets/img/book-shop/bookshop2.jpg" class="img-fluid">
          </div>
          <div class="col-6 col-sm-4">
              <img src="assets/img/book-shop/BookShop3.jpg" class="img-fluid">
          </div>
          <div class="col-6 col-sm-4">
              <img src="assets/img/book-shop/BookShop4.jpg" class="img-fluid">
          </div>
          <div class="col-6 col-sm-4">
              <img src="assets/img/book-shop/BookShop5.jpg" class="img-fluid">
          </div>
          <div class="col-6 col-sm-4">
              <img src="assets/img/book-shop/BookShop6.jpg" class="img-fluid">
          </div>
          <div class="col-6 col-sm-4">
              <img src="assets/img/book-shop/BookShop7.jpg" class="img-fluid">
          </div>
        </div>
      </div>
    </div>
  </div>
</div>