<app-header></app-header>
<!-- ==== Header ==== -->
    <!-- Main -->
    <div class="contact-section">
        <div class="container-fluid login-form">
            <div class="card row-box-shadow">
                <div class="box-shadow rounded o-hidden">
                    <form [formGroup]="loginform" #Userlogin="ngForm" autocomplete="off">
                        <div class="login-input">
                            <label for="Email">Email</label> 
                            <i class="fa fa-mobile" aria-hidden="true"></i>
                            <input class="form-control"  type="text" formControlName="email"  id="email" />
                        </div>
                        <div class="login-input">
                            <label for="Password">Password</label> 
                            <i class="fa fa-unlock-alt" aria-hidden="true"></i>
                            <input  class="form-control" type="password" formControlName="password" />
                        </div>
                        <a class="forgot-password" routerLink="/dashboard" >Forgot password?</a>
                        <div clasc="login-footer">
                            <button class="btn login-btn btn-block" (click)="login()" >LOGIN</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
<!-- ==== Footer ==== -->
<app-front-end-footer></app-front-end-footer>