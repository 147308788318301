<app-header></app-header>
<!-- ==== Header ==== -->
    <app-page-title heading1="Osho kundalini meditation" heading2="Osho kundalini meditation"></app-page-title>

    <section class="section-padding">
        <div class="container-fluid">
          <div class="row">
            <div class="col-sm-12">
            <div class="meditation-box">
              <img src="assets/img/OSHO-Kundalini.jpg" class="img-fluid">
              <p>This "sister meditation" to the OSHO Dynamic is best done at sunset or in the late afternoon. Being fully immersed in the shaking and dancing of the first two stages helps to “melt” the rock-like being, wherever the energy flow has been repressed and blocked. Then that energy can flow, dance and be transformed into bliss and joy. The last two stages enable all this energy to flow vertically, to move upwards into silence. It is a highly effective way of unwinding and letting go at the end of the day.</p>

              <p><strong>Osho on How to Shake:</strong></p>

              <p>
                "If you are doing the kundalini meditation, allow the shaking – don't do it! Stand silently, feel it coming, and when your body starts a little trembling, help it, but don't do it! Enjoy it, feel blissful about it, allow it, receive it, welcome it, but don't will it.
              </p>

              <p>
                "If you force, it will become an exercise, a bodily physical exercise. Then the shaking will be there, but just on the surface. It will not penetrate you. You will remain solid, stonelike, rocklike within. You will remain the manipulator, the doer, and the body will only be following. The body is not the question, you are the question.
              </p>

              <p>
                "When I say shake, I mean your solidity, your rocklike being should shake to the very foundations, so it becomes liquid, fluid, melts, flows. And when the rocklike being becomes liquid your body will follow. Then there is no shaker, only shaking; then nobody is doing it, it is simply happening. Then the doer is not.
              </p>

              <p>
                "Enjoy it, but don't will it. And remember, whenever you will a thing you cannot enjoy it. They are reverse, opposites; they never meet. If you will a thing you cannot enjoy it, if you enjoy it you cannot will it." Osho
              </p>

              <p>
                <strong>Instructions:</strong>
                The meditation is one hour long, with four stages.
              </p>

              <p>
                <strong>First Stage: 15 minutes</strong>
                Be loose and let your whole body shake, feeling the energies moving up from your feet. Let go everywhere and become the shaking. Your eyes may be open or closed.
              </p>

              <p>
                <strong>Second Stage: 15 minutes</strong>
                Dance ...  any way you feel, and let the whole body move as it wishes. Again, your eyes can be open or closed.
              </p>

              <p>
                  <strong>Third Stage: 15 minutes</strong>
                  Close your eyes and be still, sitting or standing, observing, witnessing, whatever is happening inside and out.
              </p>
              <p>
                <strong>Fourth Stage: 15 minutes</strong>
                Keeping your eyes closed, lie down and be still.
            </p>

            </div>
            </div>
          </div>
        </div>
    </section>


<!-- ==== Footer ==== -->
<app-front-end-footer></app-front-end-footer>