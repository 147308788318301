import { Component, OnInit, TemplateRef } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { HomepagesliderService } from './../../../../Services/homepageslider.service';
import { MessagePanelService } from './../../../../Services/message-panel.service';
import { Globals } from './../../../../global';
import { LocalStorageService } from './../../../../Services/local-storage.service';
import { Router } from '@angular/router';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss']
})
export class SliderComponent implements OnInit {

  modalRef: BsModalRef;
  public data : any;
  sliderlistgetall:any =[];
  imgbaseurl="";

  constructor(public globals: Globals, private router: Router, private formBuilder: FormBuilder, private messagePanelService: MessagePanelService, private localStorageService: LocalStorageService, public homepagesliderService:HomepagesliderService,private modalService: BsModalService) { }

  
  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: false,
    autoplay:true,
    autoplayHoverPause:false,
    lazyLoad:true,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    navText: ['<i class="fa fa-angle-left next-pre" aria-hidden="true"></i>', 
              '<i class="fa fa-angle-right next-pre" aria-hidden="true"></i>'],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 1
      },
      740: {
        items: 1
      },
      940: {
        items: 1
      }
    },
    nav: true
  }

  ngOnInit(): void {
    this.imgbaseurl=this.localStorageService.getBaseUrl();
    this.getslideralldata();
  }

  getslideralldata(){
    console.log("get All Slider___")
    this.homepagesliderService.gethomeslider().subscribe(data => {
      if (data === null || data === undefined) {
        this.messagePanelService.ShowPopupMessageWithLocalization('An error occured, please try again later', this.globals.messageCloseTime, this.globals.messageType.error);
      } else if (data['success'] === true) {
        if(data.slider.length>0){
          this.sliderlistgetall = data.slider;
        }else{
          this.sliderlistgetall = data.slider;
          this.messagePanelService.ShowPopupMessageWithLocalization(data['message'], this.globals.messageCloseTime, this.globals.messageType.success);
        }
      } else if (data['statusCode'] == 400) {
        this.messagePanelService.ShowPopupMessageWithLocalization(data['message'], this.globals.messageCloseTime, this.globals.messageType.success);
      } else {
        this.messagePanelService.ShowPopupMessageWithLocalization(data['message'], this.globals.messageCloseTime, this.globals.messageType.error);
      }
    });
  }

}
