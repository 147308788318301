    <!-- Main -->
    <div class="contact-section" id="contact">
        <div class="container-fluid">
    
            <div class="section-title text-center">
                <h2>Contact Us</h2>
                <img src="assets/img/bottom-line.png" class="bottom-line">
            </div>
    
            <div class="row">
                <div class="col-12 col-sm-3">
                    <div class="contact-box text-center">
                        <h3>CONTACT INFORMATION</h3>
                        <img src="assets/img/bottom-line.png" class="bottom-line">
                        <p>Osho Manan Neo-Sannyas Commune
                            Near Railway Over Bridge,
                            Palavasana, Mehsana – 384 003
                            Gujarat, INDIA</p>
                    </div>
                </div> <!-- End -->
                <div class="col-12 col-sm-3">
                    <div class="contact-box text-center">
                        <h3>CONTACT NOS.</h3>
                        <img src="assets/img/bottom-line.png" class="bottom-line">
                        <p>+91 94289 59979,<br> 02762 - 225289</p> 
                    </div>
                </div> <!-- End -->
                <div class="col-12 col-sm-3">
                    <div class="contact-box text-center">
                        <h3>OFFICE WORKING HOURS</h3>
                        <img src="assets/img/bottom-line.png" class="bottom-line">
                        <p>9:00 am to 12.00 noon,<br>
                            2:30 pm to 6.00 pm<br>
                            9:00 pm to 10.00 pm</p>
                    </div>
                </div> <!-- End -->
                <div class="col-12 col-sm-3">
                    <div class="contact-box text-center">
                        <h3>EMAIL ID</h3>
                        <img src="assets/img/bottom-line.png" class="bottom-line">
                        <p><a href="#">contact@oshomanan.com</a> </p>
                    </div>
                </div> <!-- End -->
    
            </div>
        </div>
    </div>