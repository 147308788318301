<section class="review-section section-padding" id="review">
    <div class="container-fluid">
        <div class="section-title text-center">
            <h2>Participants review</h2>
            <img src="assets/img/bottom-line.png" class="bottom-line">
        </div>
        <owl-carousel-o [options]="customOptions">
            <ng-template carouselSlide>
                <div class="gallery-box effect1">
                    <img src="assets/img/re-01.png" class="img-fluid" alt="Image">
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc tincidunt est mauris, nec fermentum lorem.</p>
                </div>
            </ng-template>  
            <ng-template carouselSlide>
                <div class="gallery-box effect1">
                    <iframe width="100%" height="280px" src="https://www.youtube.com/embed/z7CV0425Cow" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc tincidunt est mauris, nec fermentum lorem.</p>
                </div>
            </ng-template>  
            <ng-template carouselSlide>
                <div class="gallery-box effect1">
                    <img src="assets/img/re-02.png" class="img-fluid" alt="Image">
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc tincidunt est mauris, nec fermentum lorem.</p>
                </div>
            </ng-template> 
            <ng-template carouselSlide>
                <div class="gallery-box effect1">
                    <img src="assets/img/re-01.png" class="img-fluid" alt="Image">
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc tincidunt est mauris, nec fermentum lorem.</p>
                </div>
            </ng-template>  
            <ng-template carouselSlide>
                <div class="gallery-box effect1">
                    <iframe width="100%" height="280px" src="https://www.youtube.com/embed/z7CV0425Cow" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc tincidunt est mauris, nec fermentum lorem.</p>
                </div>
            </ng-template>  
            <ng-template carouselSlide>
                <div class="gallery-box effect1">
                    <img src="assets/img/re-02.png" class="img-fluid" alt="Image">
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc tincidunt est mauris, nec fermentum lorem.</p>
                </div>
            </ng-template> 
        </owl-carousel-o>


    </div>
</section>