import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { LocalStorageService } from './local-storage.service';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class HomesliderService {

  apiUrl: string;

  constructor(@Inject('API_URL') baseUrl: string,
    private http: HttpClient,
    private localStorageService: LocalStorageService) {
    this.apiUrl = baseUrl;
  }
  getHeader() {
    return {
      headers: new HttpHeaders({
        'authorization': this.localStorageService.getUserToken()
      }),
      responseType: 'text' as 'json'
    };
  };
  getHeaderNonlogin() {
    return {
      headers: new HttpHeaders({
        'authorization': 'abcd'
      }),
      responseType: 'text' as 'json'
    };
  };

  slideradd(body): Observable<any> {
    let endpoint = '/slider/';
    return this.http.post<any>(this.apiUrl + endpoint, body, this.getHeader())
      .pipe(map(res => JSON.parse(res)), catchError(err => this.handleError(err)));
  }

  getsliderall(): Observable<any> {
    let endpoint = '/slider/get/all';
    return this.http.get<any>(this.apiUrl + endpoint, this.getHeader())
      .pipe(map(res => JSON.parse(res)), catchError(err => this.handleError(err)));
  }

  putslider(body): Observable<any> {
    let endpoint = '/slider/' + body.id;
    return this.http.put<any>(this.apiUrl + endpoint, body.form_data, this.getHeader())
      .pipe(map(res => JSON.parse(res)), catchError(err => this.handleError(err)));
  }

  deletesliderdata(body): Observable<any> {
    let endpoint = '/slider/' + body.slide_id;
    return this.http.delete<any>(this.apiUrl + endpoint, this.getHeader())
      .pipe(map(res => JSON.parse(res)), catchError(err => this.handleError(err)));
  }

  // ======================================== EVET   ==================================================

  eventadd(body): Observable<any> {
    let endpoint = '/event/';
    return this.http.post<any>(this.apiUrl + endpoint, body, this.getHeader())
      .pipe(map(res => JSON.parse(res)), catchError(err => this.handleError(err)));
  }
  putevent(body): Observable<any> {
    let endpoint = '/event/' + body.id;
    return this.http.put<any>(this.apiUrl + endpoint, body.form_data, this.getHeader())
      .pipe(map(res => JSON.parse(res)), catchError(err => this.handleError(err)));
  }

  geteventall(): Observable<any> {
    let endpoint = '/event/get/admin/all';
    return this.http.get<any>(this.apiUrl + endpoint, this.getHeader())
      .pipe(map(res => JSON.parse(res)), catchError(err => this.handleError(err)));
  }

  getpastevents(): Observable<any> {
    let endpoint = '/event/get/admin/past';
    return this.http.get<any>(this.apiUrl + endpoint, this.getHeader())
      .pipe(map(res => JSON.parse(res)), catchError(err => this.handleError(err)));
  }
  deleteeventdata(body): Observable<any> {
    let endpoint = '/event/' + body.event_id;
    return this.http.delete<any>(this.apiUrl + endpoint, this.getHeader())
      .pipe(map(res => JSON.parse(res)), catchError(err => this.handleError(err)));
  }

  // ======================================== EVET  END ==================================================
  // ======================================== EVET IMAGE UPLOAD ==================================================
  eventdetails(body): Observable<any> {
    let endpoint = '/eventdetail/';
    return this.http.post<any>(this.apiUrl + endpoint, body, this.getHeader())
      .pipe(map(res => JSON.parse(res)), catchError(err => this.handleError(err)));
  }
  geteventdetails(body): Observable<any> {
    let endpoint = '/eventdetail/' + body.event_id;
    return this.http.get<any>(this.apiUrl + endpoint, this.getHeaderNonlogin())
      .pipe(map(res => JSON.parse(res)), catchError(err => this.handleError(err)));
  }
  deleteimageupload(body): Observable<any> {
    let endpoint = '/eventdetail/' + body.id;
    return this.http.delete<any>(this.apiUrl + endpoint, this.getHeader())
      .pipe(map(res => JSON.parse(res)), catchError(err => this.handleError(err)));
  }
  // ======================================== EVET IMAGE UPLOAD END ==================================================

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof Error) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
      // this.notificationService.setError(error.error.message, false);
    } else {
      if (error.status === 400) {
        if (error.error.validationErrors) {
          error.error.validationErrors.forEach((val, index) => {
            //this.notificationService.setError(val.message);
          });
        } else {
          //  this.notificationService.setError(error.error.message, false);
        }
      } else if (error.status === 500) {
        //this.notificationService.setError('Something bad happed. Please try again.');
      } else {
        // The backend returned an unsuccessful response code.
        // The response body may contain clues as to what went wrong,
        console.log(error);
        if (error.error) {
          //this.notificationService.setError('Something bad happed. Please try again.', false);
        } else {
          //this.notificationService.setError(error.error.error_description, false);
        }
      }
    }
    return throwError('Something bad happed. Please try again.');
  }

}
