<section id="homepage">
<app-header></app-header>
    <app-slider></app-slider>
    <app-about-osho></app-about-osho>
    <app-facilities></app-facilities>
    <!-- <app-review></app-review> -->
    <app-past-event></app-past-event>
    <app-upcoming-event></app-upcoming-event>
    <app-our-facilitators></app-our-facilitators>
    <app-contact></app-contact>
    <app-notice></app-notice>
<app-front-end-footer></app-front-end-footer>
</section>