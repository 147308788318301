<div class="logo">
    <a href="/oshomanan/home" class="simple-text">
        <img src="assets/img/logo.png" />
    </a>
</div>
<div class="sidebar-wrapper">
    <div *ngIf="isMobileMenu()">
        <ul class="nav navbar-nav nav-mobile-menu">
            <li class="nav-item dropdown">
                <a class="nav-link" routerLink="/home">
                    <i class="fa fa-sign-out" aria-hidden="true"></i>
                    <span class="notification">LOGOUT</span>
                </a>
                <!-- <a class="nav-link" href="javascript:void(0)" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i class="fa fa-bell material-icons" aria-hidden="true"></i>
                <span class="notification">5</span>
                <p>
                    <span class="d-lg-none d-md-block">Some Actions</span>
                </p>
            </a>
            <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownMenuLink">
                <a class="dropdown-item" href="#">Mike John responded to your email</a>
                <a class="dropdown-item" href="#">You have 5 new tasks</a>
                <a class="dropdown-item" href="#">You're now friend with Andrew</a>
                <a class="dropdown-item" href="#">Another Notification</a>
                <a class="dropdown-item" href="#">Another One</a>
            </div> -->
            </li>
        </ul>
    </div>
    <ul class="nav">
        <li routerLinkActive="active" *ngFor="let menuItem of menuItems" class="{{menuItem.class}} nav-item">
            <a class="nav-link" [routerLink]="[menuItem.path]">
                <i class="material-icons">{{menuItem.icon}}</i>
                <p>{{menuItem.title}}</p>
            </a>
        </li>
    </ul>
</div>