import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-review',
  templateUrl: './review.component.html',
  styleUrls: ['./review.component.scss']
})
export class ReviewComponent implements OnInit {

  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    autoplay:true,
    autoplayHoverPause:false,
    lazyLoad:false,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    nav: true,
    navText: ['<i class="fa fa-angle-left" aria-hidden="true"></i>', 
              '<i class="fa fa-angle-right" aria-hidden="true"></i>'],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 2
      },
      740: {
        items: 3
      },
      940: {
        items: 3
      }
    },
  }

  constructor() { }

  ngOnInit(): void {
  }

}
