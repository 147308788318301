import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-no-dimensions-meditation',
  templateUrl: './no-dimensions-meditation.component.html',
  styleUrls: ['./no-dimensions-meditation.component.scss']
})
export class NoDimensionsMeditationComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
