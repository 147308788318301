import { Component, HostListener } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'osho-manan';

  // @HostListener('window:scroll', ['$event'])
  // onWindowScroll(e) {
  //   // console.log(window.pageYOffset);
  //    if (window.pageYOffset > 300) {     
  //      let element = document.getElementById('header');
  //      if(element){
  //       element.classList.add('sticky'); 
  //     }
  //    } else {
  //     let element = document.getElementById('header');
  //       if(element){
  //         element.classList.remove('sticky'); 
  //       }
        
  //    }
  // }
}

