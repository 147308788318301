<app-header></app-header>
<!-- ==== Header ==== -->
    <app-page-title heading1="OSHO Dynamic Meditation" heading2="OSHO Dynamic Meditation"></app-page-title>

    <section class="section-padding">
        <div class="container-fluid">
          <div class="row">
            <div class="col-sm-12">
            <div class="meditation-box">
              <img src="assets/img/OSHO-Dynamic-Meditation.jpg" class="img-fluid">
              <p>This meditation is a fast, intense and thorough way to break old, ingrained patterns in the bodymind that keep one imprisoned in the past, and to experience the freedom, the witnessing, silence and peace that are hidden behind these prison walls.</p>

              <p>The meditation is meant to be done in the early morning, when as Osho explains it, “the whole of nature becomes alive, the night has gone, the sun is coming up and everything becomes conscious and alert.”</p>

              <p>
                "This is a meditation in which you have to be continuously alert, conscious, aware, whatsoever you do. The first step, breathing; the second step, catharsis; the third step, the mantra, 'Hoo.'
                Remain a witness. Don’t get lost. It is easy to get lost. While you are breathing you can forget; you can become one with the breathing so much that you can forget the witness. But then you miss the point. Breathe as fast, as deep as possible, bring your total energy to it, but still remain a witness. Observe what is happening as if you are just a spectator, as if the whole thing is happening to somebody else, as if the whole thing is happening in the body and the consciousness is just centered and looking. This witnessing has to be carried in all the three steps. And when everything stops, and in the fourth step you have become completely inactive, frozen, then this alertness will come to its peak." Osho
              </p>

              <p>
                  <strong>Instructions:</strong>
                  The meditation lasts one hour and has five stages. Keep your eyes closed throughout, using a blindfold if necessary. It can be done alone, and can be even more powerful if it is done with others.
              </p>

              <p>
                <strong>First Stage: 10 minutes</strong>
                Breathing chaotically through the nose, let breathing be intense, deep, fast, without rhythm, with no pattern – and concentrating always on the exhalation. The body will take care of the inhalation. The breath should move deeply into the lungs. Do this as fast and as hard as you possibly can until you literally become the breathing. Use your natural body movements to help you to build up your energy. Feel it building up, but don’t let go during the first stage.
              </p>

              <p>
                <strong>Second Stage: 10 minutes</strong>
                EXPLODE! … Let go of everything that needs to be thrown out. Follow your body. Give your body freedom to express whatever is there. Go totally mad. Scream, shout, cry, jump, kick, shake, dance, sing, laugh; throw yourself around. Hold nothing back; keep your whole body moving. A little acting often helps to get you started. Never allow your mind to interfere with what is happening. Consciously go mad. Be total.
              </p>

              <p>
                <strong>Third Stage: 10 minutes</strong>
                With arms raised high above your head, jump up and down shouting the mantra, “Hoo! Hoo! Hoo!” as deeply as possible. Each time you land, on the flats of your feet, let the sound hammer deep into the sex center. Give all you have; exhaust yourself completely.
              </p>

              <p>
                <strong>Fourth Stage: 15 minutes</strong>
                STOP! Freeze wherever you are, in whatever position you find yourself. Don’t arrange the body in any way. A cough, a movement, anything, will dissipate the energy flow and the effort will be lost. Be a witness to everything that is happening to you.
              </p>

              <p>
                <strong>Fifth Stage: 15 minutes</strong>
                Celebrate! With music and dance express whatsoever is there. Carry your aliveness with you throughout the day.
              </p>

              <p>
                If your meditation space prevents you from making noise, you can do this silent alternative: rather than throwing out the sounds, let the catharsis in the second stage take place entirely through bodily movements. In the third stage, the sound Hoo! can be hammered silently inside, and the fifth stage can become an expressive dance.
              </p>

            </div>
            </div>
          </div>
        </div>
    </section>


<!-- ==== Footer ==== -->
<app-front-end-footer></app-front-end-footer>