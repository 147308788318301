<app-header></app-header>
<!-- ==== Header ==== -->
    <app-page-title heading1="OSHO GOURISHANKAR MEDITATION" heading2="Osho Gourishankar Meditation"></app-page-title>

    <section class="section-padding">
        <div class="container-fluid">
          <div class="row">
            <div class="col-sm-12">
            <div class="meditation-box">
              <img src="assets/img/OSHO-Kundalini.jpg" class="img-fluid">
              <p>The meditation lasts one hour and has four stages of 15 minutes each.</p>

              <p>Osho says that if the breathing is done correctly in the first stage of this meditation the carbon dioxide formed in the bloodstream will make you feel as high as Gourishankar (Mt. Everest).This “high” is carried into the subsequent stages of soft gazing, soft and spontaneous movement, and silent stillness.</p>

              <p>
                <strong>First Stage: 15 minutes</strong>
                Sit with closed eyes. Inhale deeply through the nose, filling the lungs. Hold the breath for as long as possible; then exhale gently through the mouth, and keep the lungs empty for as long as possible. Continue this breathing cycle throughout this stage.
              </p>

              <p>
                  <strong>Second Stage: 15 minutes</strong>
                  Return to normal breathing and with a gentle gaze look at a candle flame or a flashing blue light. Keep your body still.
              </p>

              <p>
                <strong>Note:</strong>
                Those with a neurological disorder such as epilepsy should never use a strobe or flashing light for this meditation. Instead they can do the second stage with a blindfold.
              </p>

                <p>
                    <strong>Third Stage: 15 minutes</strong>
                    With closed eyes, stand up and let your body be loose and receptive. The subtle energies will be felt to move the body outside your normal control. Allow this “Latihan” to happen. Don’t you do the moving: let moving happen, gently and gracefully.
                </p>

                <p>
                    <strong>Fourth Stage: 15 minutes</strong>
                    Lie down with closed eyes, silent and still.
                </p>

            </div>
            </div>
          </div>
        </div>
    </section>


<!-- ==== Footer ==== -->
<app-front-end-footer></app-front-end-footer>