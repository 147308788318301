import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sindhu-prem',
  templateUrl: './sindhu-prem.component.html',
  styleUrls: ['./sindhu-prem.component.scss']
})
export class SindhuPremComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
