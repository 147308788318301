import { Component, OnInit, TemplateRef,ViewChild } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { LoginpageService } from './../../../Services/loginpage.service';
import { MessagePanelService } from './../../../Services/message-panel.service';
import { LocalStorageService} from './../../../Services/local-storage.service';
import { UserinfoModule} from './../../../model/userinfo/userinfo.module';
import { Globals } from './../../../global';


@Component({
  selector: 'app-loginpage',
  templateUrl: './loginpage.component.html',
  styleUrls: ['./loginpage.component.scss']
})
export class LoginpageComponent implements OnInit {

  
Userdata:UserinfoModule;

  constructor( public globals:Globals,private router: Router,private messagePanelService: MessagePanelService, private formBuilder: FormBuilder, public loginpageService:LoginpageService,  private localStorageService: LocalStorageService,private route: ActivatedRoute) { }

  loginform = this.formBuilder.group({
    email: ['',Validators.required],  
    password: ['',Validators.required], 
  });


  async login() {
    let email = this.loginform.controls['email'].value;
    let password = this.loginform.controls['password'].value;

    if (!email) {
      this.messagePanelService.ShowPopupMessageWithLocalization("Please Enter Valid Email", this.globals.messageCloseTime, this.globals.messageType.error);
      return false;
    }else if (!password) {
      this.messagePanelService.ShowPopupMessageWithLocalization("Please Enter Valid Password", this.globals.messageCloseTime, this.globals.messageType.error);
      return false;
    }else {
      
      let obj= {"email": email,"password":password};
      console.log("Add___Obj______",obj);

      this.loginpageService.LoginAdmin(obj).subscribe(data=>{
        if (data === null || data === undefined) {
          this.messagePanelService.ShowPopupMessageWithLocalization('An error occured, please try again later',this.globals.messageCloseTime,this.globals.messageType.error);
        }else if(data['success']==true){
          console.log("login ADD------------------",data);
          this.Userdata=data.user;
          this.Userdata.islogin=true;
          this.localStorageService.saveUserDetail(this.Userdata);
          this.router.navigate(['/dashboard']);
        }else if(data['statusCode']==400){
          this.messagePanelService.ShowPopupMessageWithLocalization(data['message'],this.globals.messageCloseTime,this.globals.messageType.success);
        }else{
          this.messagePanelService.ShowPopupMessageWithLocalization(data['message'],this.globals.messageCloseTime,this.globals.messageType.error);
        }
      });
    }
  } 


  ngOnInit(): void {
  }

}
