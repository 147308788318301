<div class="wrapper">
    <div class="sidebar" data-color="danger" data-background-color="white" data-image="./assets/img/sidebar-1.jpg">
        <app-sidebar></app-sidebar>
        <div class="sidebar-background" style="background-image: url(./assets/img/sidebar-4.jpg)"></div>
    </div>
    <div class="main-panel">
        <app-navbar></app-navbar>
        <router-outlet></router-outlet>
        <div *ngIf="isMaps('maps')">
            <!-- <app-footer></app-footer> -->
        </div>
    </div>
</div>
<!-- Buy-Modal-angular -->
<div class="modal modal-angular fade" id="buy" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
<div class="modal-dialog" role="document">
    <div class="modal-content">
        <div class="modal-body text-center">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
            <h4 class="margin-top">
                Free Version
            </h4>
            <div class="separator"></div>
            <a href="#" class="modal-button" target="_blank">
                <div class="wrapper-card">
                    <div class="image-container">
                        <!-- <img src="./assets/img/html.png" alt="unloaded"> -->
                    </div>
                    Html5
                    <div class="separator"></div>
                    <div class="product-type">
                        FREE
                    </div>
                </div>
            </a>
            <a href="#" class="modal-button" target="_blank">
                <div class="wrapper-card">
                    <div class="image-container image-angular-cli">
                        <!-- <img src="./assets/img/angular.png" alt="unloaded"> -->
                    </div>
                    Angular
                    <div class="separator"></div>
                    <div class="product-type">
                        FREE
                    </div>
                </div>
            </a>
            <h4>
                PRO Version
            </h4>
            <div class="separator"></div>
            <a href="#" class="modal-button" target="_blank">
                <div class="image-container">
                    <!-- <img src="./assets/img/html.png" alt="unloaded"> -->
                </div>
                Html5
                <div class="separator"></div>
                <div class="price">
                    from
                    <span>
                        49
                        <i class="fa fa-usd" aria-hidden="true"></i>
                    </span>
                </div>
            </a>
            <a href="#" class="modal-button" target="_blank">
                <div class="image-container image-angular-cli">
                    <!-- <img src="./assets/img/angular.png" alt="unloaded"> -->
                </div>
                Angular
                <div class="separator"></div>
                <div class="price">
                    from
                    <span>
                        59
                        <i class="fa fa-usd" aria-hidden="true"></i>
                    </span>
                </div>
            </a>
        </div>
    </div>
</div>

</div>
