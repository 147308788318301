<section class="upcoming-section section-padding" id="calendar">
    <div class="container-fluid">
        <div class="section-title text-center">
            <h2>Upcoming Events CALENDAR</h2>
            <img src="assets/img/bottom-line.png" class="bottom-line">
        </div>

        <div class="event-list" *ngFor="let event of getcalenderall; let i = index">
            <div class="event-row">
                <div class="row align-items-center">
                    <div class="col-12 col-sm-3">
                        <img [src]="event.image? imgbaseurl+event.image : ''"  class="event-thubnel">
                    </div>
                    <div class="col-12 col-sm-9 event-text">
                        <div class="date">{{event.event_startdate | date:'dd MMM yyyy'}}</div>
                        <p>{{event.event_title}}</p>
                        <p>{{event.event_desc}}</p>
                    </div>
                </div>
            </div>
        </div>

    </div>
</section>