import { Component, OnInit } from '@angular/core';
import $ from 'jquery';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  // shouldShow = false;
  // shouldHide = false;

  constructor() {

    $(document).ready(function () {
      $('ul.nav > li').click(function (e) {
          e.preventDefault();
          $('ul.nav > li').removeClass('active');
          $(this).addClass('active');                
      });            
    });

   }

  ngOnInit(): void {
    window.scrollTo(0, 0);
  }

}
