<section class="notic-section">
    <p>
        Note: Please bring your photo ID Card<br>
        Maroon and white robes compulsory<br>
        Entry in Meditation Hall restricted for children below 12 years of age
    </p>
</section>

<iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d234066.54556473708!2d72.375505!3d23.558034!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xb0fe6a19d5a31f15!2sOsho%20Manan%20Neo-Sannyas%20Commune!5e0!3m2!1sen!2sin!4v1614692462431!5m2!1sen!2sin" width="100%" height="450px" style="border:0;" allowfullscreen="" loading="lazy"></iframe>

<div class="how-to-reach">
    <a data-toggle="modal" data-target="#how-reach" class="theme-btn">Click here For How to Reach <i class="fa fa-angle-right" aria-hidden="true"></i></a>
</div>


<!-- Modal -->
<div class="modal fade" id="how-reach" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">HOW TO REACH</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body how-reach-popup">
            <div class="mb-3">
                <img src="assets/img/gujratmap.jpg">
                <h3>Air:</h3>
                <p>Various domestic & International airlines connect Ahmedabad with the major cities of India & World.</p>
                <h3>Rail:</h3>
                <p>On the Western Railway with direct connections to Ahmedabad.</p> 
                <h3>Road:</h3> 
                <p>State transport buses and private luxury coaches connect Mehsana with various centres of Gujarat.</p>
                <p>It is very easy to reach Mehsana from any location of Gujarat or Once you reach Ahmedabad For any guidance, contact Our Osho Meditation Centre in Ahmedabad : Sw. Radheshyam Saraswati on (+91)-(079)-27680178.</p> 
            </div>

            <div class="mb-3">
                <img src="assets/img/indiamap.jpg">
                <h3>From Ahmedabad:</h3>
                <p>(Osho Manan Commune is just 71 kms away from Ahmedabad on Mehsana-Ahmedabd High-Way)</p>
                <h3>Railway:</h3>
                <p>There are frequent trains running from Ahmedabad to Mehsana.</p> 
                <h3>Road:</h3> 
                <p>State transport buses and private luxury coaches connect Mehsana with various centers of Gujarat.</p>
                <p>» Taxi is easily available from Ahmedabad.</p>
                <p>» If you are coming from Ahmedabad through Bus, Private Car or any taxi you don't need to go Mehsana because Osho Manan Commune falls 5 kms before Mehsana on highway.</p> 
            </div>

            <div class="mb-3">
                <img src="assets/img/mehsanamap.jpg">
                <h3>From Mehsana:</h3>
                <p>(Osho Manan Commune is just 5 Kms away from Mehsana on Mehsana - Ahmedabad High-Way)</p>
                <p>From Mehsana railway station and bus station auto rickshaws can take one to commune in 30-40 rupees.</p>
            </div>
            
        </div>
      </div>
    </div>
  </div>