<app-header></app-header>
<!-- ==== Header ==== -->
<app-page-title heading1="Facilitators Details" heading2="Devendra"></app-page-title>

<section class="section-padding">
    <div class="container">
      <div class="row">
        <div class="col-sm-12">
        <div class="facilitation-box">
          <img src="assets/img/devendra.png" class="img-fluid">
          <p>
            He is a Graduate in Science (Biology) and in Law. Devendra is a Facilitator, Trainer and coach in the areas of Meditation & Mindfulness, Stress Management, Emotional Wellness, Communication, Counselling and other skills for better and conscious life. With personal experience of more than 40 years in the field of Self-development, he is working with people from different countries and from all walks of life. He is travelling around the world for Meditation Events, Workshops, Trainings .
          </p>
        </div>
        </div>
      </div>
    </div>
</section>

<!-- ==== Footer ==== -->
<app-front-end-footer></app-front-end-footer>