<app-header></app-header>
<!-- ==== Header ==== -->
<app-page-title heading1="Facilitators Details" heading2="Dhyan Rajiv"></app-page-title>

<section class="section-padding">
    <div class="container">
      <div class="row">
        <div class="col-sm-12">
        <div class="facilitation-box">
          <img src="assets/img/dhyan-rajiv.png" class="img-fluid">
            <p>Dhyan Rajiv has spent several years training with many of the facilitators who have been very close to OSHO. Prior to this he did explore and learn from a wide variety of other schools ranging from western psychology / counselling approaches in London, Bioenergetics in Brazil to being with the Shamans in the Amazon jungles in Peru etc ! He calls himself an existential seeker. He loves OSHO, appreciates the precious and varied meditation techniques gifted by OSHO to humankind and is passionate about sharing OSHO's work. While he first heard OSHO for the first time in 1978 as both his parents are sannyasins, he has been visiting OIMR Pune since 2001.</p>
            <p>Rajiv facilitates Osho Meditative therapies like OSHO Mystic Rose, OSHO No Mind and OSHO Born Again, OSHO meditations and other courses and one to one sessions at OSHO Multiversity at OIMR Pune for the last few years.</p> 
            <p>Rajiv can offer Transomatic Dialogues Therapy and Felt sense awareness (Isness) sessions online. He speaks English and Hindi fluently.</p>
        </div>
        </div>
      </div>
    </div>
</section>

<!-- ==== Footer ==== -->
<app-front-end-footer></app-front-end-footer>