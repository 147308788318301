<app-header></app-header>
<!-- ==== Header ==== -->
    <app-page-title heading1="OSHO MANDALA MEDITATION" heading2="Osho Mandala Meditation"></app-page-title>

    <section class="section-padding">
        <div class="container-fluid">
          <div class="row">
            <div class="col-sm-12">
            <div class="meditation-box">
              <img src="assets/img/OSHO-Mandala.jpg" class="img-fluid">
              <p>This meditation lasts one hour and there are four stages of 15 minutes each.</p>

              <p>Every circle contains a center. In the first three stages of this energetic and powerful technique “centering” is the aim, through the creation of a circle of energy. Then, in the fourth stage, the relaxation.</p>

              <p>
                <strong>First Stage: 15 minutes</strong>
                With open eyes run on the spot, starting slowly and gradually, getting faster and faster. Bring your knees up as high as possible. Breathing deeply and evenly will move the energy within. Forget the mind and forget the body. Keep going.
              </p>

              <p>
                  <strong>Second Stage: 15 minutes</strong>
                  Sit down with eyes closed, mouth open and loose. Let your body sway from the waist, like a reed blowing in the wind – from side to side, back and forth, around and around as it happens. This will gather your awakened energies at the navel center.
              </p>

              <p>
                <strong>Third Stage: 15 minutes</strong>
                Lie on your back, keeping your head still. Open your eyes and rotate them in a clockwise direction. Sweep them fully around in the sockets as if you are following the second hand of a vast clock. Start slowly, then gradually turn your eyes faster and faster. Let your mouth remain open and the jaw relaxed, with soft and even breathing.<br>
                This will bring your centered energies to the “third eye” center.
              </p>

                <p>
                    <strong>Fourth Stage: 15 minutes</strong>
                    Close your eyes and be still.
                </p>

            </div>
            </div>
          </div>
        </div>
    </section>


<!-- ==== Footer ==== -->
<app-front-end-footer></app-front-end-footer>