import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-front-end-footer',
  templateUrl: './front-end-footer.component.html',
  styleUrls: ['./front-end-footer.component.scss']
})
export class FrontEndFooterComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
