<div class="title">
    <div class="container  mb-80">
        <div class="row">
            <div class="col-sm" data-aos="fade-down"  data-aos-duration="600">
                <h2 class="text-center">{{heading1}}</h2>
                <p class="text-center"><a routerLink="/home">Home</a> <i class="fa fa-angle-right" aria-hidden="true"></i> 
                <span>{{heading2}}</span></p>
            </div>
        </div>
    </div>
</div>