<div class="home-slider">
    <owl-carousel-o [options]="customOptions">
     <ng-template carouselSlide *ngFor="let slider of sliderlistgetall; let i = index" [id]="slider.id" >
         <div class="product-box">
           <img [src]="slider.image? imgbaseurl+slider.image : ''" alt="Slider Image" class="d-block w-100">
           <div class="container">
             <div class="carousel-caption">
                 <!-- <h1>{{slider.title}}</h1> -->
             </div>
           </div>
       </div>
     </ng-template>  
   </owl-carousel-o>
</div>