import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-gourishankar-meditation',
  templateUrl: './gourishankar-meditation.component.html',
  styleUrls: ['./gourishankar-meditation.component.scss']
})
export class GourishankarMeditationComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
