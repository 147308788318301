import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { Globals } from './../app/global';
import { HttpClientModule } from "@angular/common/http";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import 'hammerjs';
import { AppRoutingModule } from './app-routing.module';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { AppComponent } from './app.component';
import { HomeComponent } from './shared/component/home/home.component';
import { HeaderComponent } from './shared/component/header/header.component';
import { SliderComponent } from './shared/component/home/slider/slider.component';
import { AboutOshoComponent } from './shared/component/home/about-osho/about-osho.component';
import { FacilitiesComponent } from './shared/component/home/facilities/facilities.component';
import { ReviewComponent } from './shared/component/home/review/review.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { PastEventComponent } from './shared/component/home/past-event/past-event.component';
import { UpcomingEventComponent } from './shared/component/home/upcoming-event/upcoming-event.component';
import { OurFacilitatorsComponent } from './shared/component/home/our-facilitators/our-facilitators.component';
import { NoticeComponent } from './shared/component/home/notice/notice.component';
import { NgxImageGalleryModule } from 'ngx-image-gallery';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PageTitleComponent } from './shared/component/page-title/page-title.component';
import { FacilitatorDtlsComponent } from './shared/component/home/our-facilitators/facilitator-dtls/facilitator-dtls.component';
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { ComponentsModule } from './components/components.module';
import { FrontEndFooterComponent } from './shared/component/front-end-footer/front-end-footer.component';
import { OshoActiveMeditationsComponent } from './shared/component/osho-active-meditations/osho-active-meditations.component';
import { OshoDetailsComponent } from './shared/component/osho-details/osho-details.component';
import { LoginpageComponent } from './shared/component/loginpage/loginpage.component';
import { DevendraComponent } from './shared/component/home/our-facilitators/facilitator-dtls/devendra/devendra.component';
import { AnilComponent } from './shared/component/home/our-facilitators/facilitator-dtls/anil/anil.component';
import { PremSadhanaComponent } from './shared/component/home/our-facilitators/facilitator-dtls/prem-sadhana/prem-sadhana.component';
import { SindhuPremComponent } from './shared/component/home/our-facilitators/facilitator-dtls/sindhu-prem/sindhu-prem.component';
import { DhyanRajivComponent } from './shared/component/home/our-facilitators/facilitator-dtls/dhyan-rajiv/dhyan-rajiv.component';
import { KundaliniMeditationComponent } from './shared/component/osho-active-meditations/kundalini-meditation/kundalini-meditation.component';
import { NadabrahmaMeditationComponent } from './shared/component/osho-active-meditations/nadabrahma-meditation/nadabrahma-meditation.component';
import { NatarajMeditationComponent } from './shared/component/osho-active-meditations/nataraj-meditation/nataraj-meditation.component';
import { DevavaniMeditationComponent } from './shared/component/osho-active-meditations/devavani-meditation/devavani-meditation.component';
import { GourishankarMeditationComponent } from './shared/component/osho-active-meditations/gourishankar-meditation/gourishankar-meditation.component';
import { MandalaMeditationComponent } from './shared/component/osho-active-meditations/mandala-meditation/mandala-meditation.component';
import { WhirlingMeditationComponent } from './shared/component/osho-active-meditations/whirling-meditation/whirling-meditation.component';
import { NoDimensionsMeditationComponent } from './shared/component/osho-active-meditations/no-dimensions-meditation/no-dimensions-meditation.component';
import { ChakraSoundsMeditationComponent } from './shared/component/osho-active-meditations/chakra-sounds-meditation/chakra-sounds-meditation.component';
import { ChakraBreathingMeditationComponent } from './shared/component/osho-active-meditations/chakra-breathing-meditation/chakra-breathing-meditation.component';
import { OshoMysticRoseComponent } from './shared/component/osho-active-meditations/osho-mystic-rose/osho-mystic-rose.component';
import { OshoBornAgainComponent } from './shared/component/osho-active-meditations/osho-born-again/osho-born-again.component';
import { NoMindComponent } from './shared/component/osho-active-meditations/no-mind/no-mind.component';
import { TalkingYourBodymindComponent } from './shared/component/osho-active-meditations/talking-your-bodymind/talking-your-bodymind.component';
import { ContactComponent } from './shared/component/home/contact/contact.component';
import { UploadImageComponent } from './dashboard-component/featurs/upload-image/upload-image.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FrontEndFooterComponent,
    HomeComponent,
    SliderComponent,
    AboutOshoComponent,
    FacilitiesComponent,
    ReviewComponent,
    PastEventComponent,
    UpcomingEventComponent,
    OurFacilitatorsComponent,
    ContactComponent,
    NoticeComponent,
    AdminLayoutComponent,
    PageTitleComponent,
    FacilitatorDtlsComponent,
    OshoActiveMeditationsComponent,
    OshoDetailsComponent,
    LoginpageComponent,
    DevendraComponent,
    AnilComponent,
    PremSadhanaComponent,
    SindhuPremComponent,
    DhyanRajivComponent,
    KundaliniMeditationComponent,
    NadabrahmaMeditationComponent,
    NatarajMeditationComponent,
    DevavaniMeditationComponent,
    GourishankarMeditationComponent,
    MandalaMeditationComponent,
    WhirlingMeditationComponent,
    NoDimensionsMeditationComponent,
    ChakraSoundsMeditationComponent,
    ChakraBreathingMeditationComponent,
    OshoMysticRoseComponent,
    OshoBornAgainComponent,
    NoMindComponent,
    TalkingYourBodymindComponent,
    UploadImageComponent
  ],
  imports: [
    BrowserModule,
    RouterModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    AppRoutingModule,
    ComponentsModule,
    CarouselModule,
    FontAwesomeModule,
    NgxImageGalleryModule,
    BrowserAnimationsModule,
    TooltipModule.forRoot(),
    ModalModule.forRoot()
  ],
  providers: [
    { provide: 'API_URL', useFactory: getApiUrl },
    Globals,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

export function getApiUrl() {
  //return 'http://168.235.64.167:8998/';
  // return 'http://localhost:8181/';
  return 'https://www.api.oshomanan.teengineers.co.in/api/'
}