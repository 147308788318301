<app-header></app-header>
<!-- ==== Header ==== -->
<app-page-title heading1="Facilitators Details" heading2="Amrit sadhana"></app-page-title>

<section class="section-padding">
    <div class="container">
      <div class="row">
        <div class="col-sm-12">
        <div class="facilitation-box">
          <img src="assets/img/amrit-sadhana.png" class="img-fluid">
          <p>
            Amrit sadhana is an experienced facilitator of the OSHO International Online. Trained by OSHO Multiversity, she is a trainer of OSHO Meditative Therapies and the course for trainers, OSHO Meditation In Depth. She has been facilitating meditations at the OSHO International Meditation Resort, Pune, India, as well as around the world for past thirty years. She also looks after OSHO press and media and is the spokesperson of the meditation resort. She has written about three thousand articles
            based on OSHO insights in English and Hindi language. She has worked in China, France, Portugal, Canada, USA, UK, Mauritius and almost all the cities of India. It gives her a profound experience of working with a wide range of international people.
          </p>
        </div>
        </div>
      </div>
    </div>
</section>

<!-- ==== Footer ==== -->
<app-front-end-footer></app-front-end-footer>