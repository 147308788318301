<app-header></app-header>
<!-- ==== Header ==== -->
    <app-page-title heading1="OSHO NADABRAHMA MEDITATION" heading2="Osho Nadabrahma Meditation"></app-page-title>

    <section class="section-padding">
        <div class="container-fluid">
          <div class="row">
            <div class="col-sm-12">
            <div class="meditation-box">
              <img src="assets/img/Osho-Nadabrahma.jpg" class="img-fluid">
              <p>Nadabrahma is the humming meditation – through humming and hand movements conflicting parts of you start falling in tune, and you bring harmony to your whole being. Then, with body and mind totally together, you “slip out of their hold” and become a witness to both. This watching from the outside is what brings peace, silence and bliss.</p>

              <p>“So in Nadabrahma, remember this: let the body and mind be totally together, but remember that you have to become a witness. Get out of them, easily, slowly, from the back door, with no fight, with no struggle.” Osho</p>

              <p>
                <strong>Instructions:</strong>
                The meditation lasts an hour, and there are three stages. Your eyes remain closed throughout.
              </p>

              <p>
                <strong>First Stage: 30 minutes</strong>
                Sit in a relaxed position with eyes closed and lips together. Start humming, loudly enough to be heard by others and create a vibration throughout your body. You can visualize a hollow tube or an empty vessel, filled only with the vibrations of the humming. A point will come when the humming continues by itself and you become the listener. There is no special breathing and you can alter the pitch or move your body smoothly and slowly if you feel it.
              </p>

              <p>
                <strong>Second Stage: 15 minutes</strong>
                The second stage is divided into two 7½ minute sections. For the first half move the hands, palms up, in an outward circular motion. Starting at the navel, both hands move forwards and then divide to make two large circles mirroring each other left and right. The movement should be so slow that at times there will appear to be no movement at all. Feel that you are giving energy outwards to the universe.
                After 7½ minutes turn the hands, palms down, and start moving them in the opposite direction. Now the hands will come together towards the navel and divide outwards to the sides of the body. Feel that you are taking energy in. As in the first stage, don’t inhibit any soft, slow movements of the rest of your body.
              </p>

              <p>
                  <strong>Third Stage: 15 minutes</strong>
                  Now stop the hand movement and just sit relaxed.
              </p>

            </div>
            </div>
          </div>
        </div>
    </section>


<!-- ==== Footer ==== -->
<app-front-end-footer></app-front-end-footer>