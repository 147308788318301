<app-header></app-header>
<!-- ==== Header ==== -->
    <app-page-title heading1="OSHO NO-DIMENSIONS MEDITATION" heading2="Osho No-Dimensions Meditation"></app-page-title>

    <section class="section-padding">
        <div class="container-fluid">
          <div class="row">
            <div class="col-sm-12">
            <div class="meditation-box">
              <img src="assets/img/OSHO-No-Dimensions.jpg" class="img-fluid">
              <p>The meditation lasts one hour and has three stages. In the first two stages the eyes are open but not focused on anything in particular. In the third stage the eyes are closed. The meditation is over when you hear three gongs.</p>

              <p>This active centering meditation is based on Sufi techniques, further developed and expanded by Osho. Using the breath and a series of coordinated body movements followed by whirling, your energy becomes centered in the hara, the “life energy” center below the navel. From there you can watch the mind and experience awareness and wholeness – the body moving in all directions, the center unmoving.</p>

              <p>
                <strong>First stage: SUFI MOVEMENTS 30 minutes</strong>
                This is a six-part movement sequence, repeated continuously and accompanied by the sound “Shoo” rising from your navel to your throat. The sound helps your movements to become more free and easy as the meditation progresses. Make the movements and sounds with a loving heart and a centered awareness.
              </p>

              <p>
                Begin by standing in one place, left hand on the heart center and right hand on the hara or navel center – until a bell rings and signals the start of the movement sequence:
              </p>

              <p>
                1. Move both hands onto the hara, the backs of the hands touching together, pointing downwards. Breathing in through the nose, bring the hands up to the heart center.<br>
                Breathing out with the sound “Shoo” move your left hand back down to the hara, and at the same time move your right arm forward (palm down) and move your right foot forward one step.<br>
                Return to the original position with both hands on the hara.
              </p>

              <p>
                2. Repeat this same movement with the left arm and foot forward.<br> Return to the original position with both hands on the hara.
              </p>

              <p>
                3. Repeat this movement with the right arm and foot now turning 90° sideways to the right.<br> Return to the original position with both hands on the hara.
              </p>

              <p>
                4. Repeat this movement with the left arm and foot now turning 90° sideways to the left.<br> Return to the original position with both hands on the hara.
              </p>

              <p>
                5. Repeat this movement with the right arm and foot now turning 180° clockwise to the right.<br> Return to the original position with both hands on the hara.
              </p>

              <p>
                Repeat this movement with the left arm and foot now turning 180° counter-clockwise to the left.<br> Return to the original position with both hands on the hara.
              </p>

              <p>
                The hips and eyes always face the direction of the hand movement. Use graceful movements in a continuous flow, in rhythm with the music. This stage of the meditation starts slowly and builds up in intensity. While the music gradually becomes faster, the stillness of the center becomes more evident.
              </p>

              <p>
                If you are doing this as a group you may get out of synchronicity with the others. When that happens, simply get back into the same rhythm as everyone else.<br>
                This stage is over when the music comes to a stop.
              </p>

              <p>
                <strong>Second stage: WHIRLING 15 minutes</strong>
                With arms folded across your chest for a few moments bow down, in loving gratitude to existence and to yourself.<br>
                Once the tempo of the music changes, begin whirling to the left or to the right, whichever feels best. If you whirl to the right, start with the right foot and right arm to the right and extend the left arm in the opposite direction. While you are whirling you can change your hands to any position which feels good to you. You whirl just like small children go on twirling.<br>
                If you have not whirled before start out very slowly; once your mind and body get attuned to the movements the body will naturally go faster. If you get dizzy, it is okay to stop for a moment, then start again. To end the whirling, slow down with the music and again fold your arms across your heart center.
              </p>

              <p>
                <strong>Third stage: SILENCE 15 minutes</strong>
                Lie down on the belly, eyes closed, allowing all the energy you have gathered to flow through you. If you find it uncomfortable to lie on the belly, lie on the back. There is nothing to do, just be.
              </p>

            </div>
            </div>
          </div>
        </div>
    </section>


<!-- ==== Footer ==== -->
<app-front-end-footer></app-front-end-footer>