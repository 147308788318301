<section class="upcoming-section section-padding">
    <div class="container-fluid container-set">
        <div class="section-title text-center">
            <h2>Our Facilitators</h2>
            <img src="assets/img/bottom-line.png" class="bottom-line">
        </div>

        <div class="row facilitator-row justify-content-center">
            <div class="col-12 col-sm-3 col-md-3 col-lg-2">
                <div class="facilitator-box" routerLink="/member-details">
                    <div class="user-img-box">
                        <img src="assets/img/amrit-sadhana.png">
                    </div>
                    <div class="dtls-box">
                        <div class="user-name">Amrit sadhana</div>
                        <p>Amrit sadhana is an experienced facilitator of the OSHO International Online.</p>
                        <div class="countery">India</div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-sm-3 col-md-3 col-lg-2">
                <div class="facilitator-box" routerLink="/devendra">
                    <div class="user-img-box">
                        <img src="assets/img/devendra.png">
                    </div>
                    <div class="dtls-box">
                        <div class="user-name">Devendra</div>
                        <p>He is a Graduate in Science (Biology) and in Law. Devendra is a Facilitator, Trainer and coach</p>
                        <div class="countery">India</div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-sm-3 col-md-3 col-lg-2">
                <div class="facilitator-box" routerLink="/anil">
                    <div class="user-img-box">
                        <img src="assets/img/anil-chandwani.png">
                    </div>
                    <div class="dtls-box">
                        <div class="user-name">Anil Chandwani</div>
                        <p>Anil Chandwani has extensive experience in leading personal development workshops and trainings worldwide.</p>
                        <div class="countery">INDIA</div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-sm-3 col-md-3 col-lg-2">
                <div class="facilitator-box" routerLink="/prem-sadhana">
                    <div class="user-img-box">
                        <img src="assets/img/prem-sadhana.png">
                    </div>
                    <div class="dtls-box">
                        <div class="user-name">Prem Sadhana</div>
                        <p>I am born in Germany. After my master degree in Clinical Psychology I started working with children.</p>
                        <div class="countery">India</div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-sm-3 col-md-3 col-lg-2">
                <div class="facilitator-box" routerLink="/sindhu-prem">
                    <div class="user-img-box">
                        <img src="assets/img/sindhu-prem.png">
                    </div>
                    <div class="dtls-box">
                        <div class="user-name">Sindhu Prem</div>
                        <p>Sindhu Prem has 25 years of experience in Meditation & Healing Therapies. She started her journey in 1995</p>
                        <div class="countery">India</div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-sm-3 col-md-3 col-lg-2">
                <div class="facilitator-box" routerLink="/dhyan-rajiv">
                    <div class="user-img-box">
                        <img src="assets/img/dhyan-rajiv.png">
                    </div>
                    <div class="dtls-box">
                        <div class="user-name">Dhyan Rajiv</div>
                        <p>Dhyan Rajiv has spent several years training with many of the facilitators who have been very close to OSHO.</p>
                        <div class="countery">India</div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</section>