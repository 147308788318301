<app-header></app-header>
<!-- ==== Header ==== -->
<app-page-title heading1="Facilitators Details" heading2="Anil Chandwani"></app-page-title>

<section class="section-padding">
    <div class="container">
      <div class="row">
        <div class="col-sm-12">
        <div class="facilitation-box">
          <img src="assets/img/anil-chandwani.png" class="img-fluid">
          <p>
            Anil Chandwani has extensive experience in leading personal development workshops and trainings worldwide.</p>
            <p>With his entrepreneurial experience and trainings in Osho active meditations, clinical hypnosis, breathe and science of chakras, he supports entrepreneurs, leaders, seekers, parents and children with transformative breakthroughs to reset their lives for more authentic, boundless and connected living.</p>
            <p>His innovative and inspiring style is characterized by his 25 years experience, enthusiasm, passion, playfulness and the visible love for the work he does. He infuses his unique approach to meditation for achieving stress-free success, bridging meditation with the marketplace, health and wellness and life-issues, through seminars in Breathe, Clinical Hypnosis, Chakra Power, and the revolutionary science of OSHO Active Meditations.</p>
            <p>He has faciliated 100’s of management and transformative courses for CEOs, business leaders and individuals in over 21 countries. Anil is also a member of the Osho International Management team. OSHO International Meditation Resort is the largest personal growth and meditation centers in the world, located in India.</p>
        </div>
        </div>
      </div>
    </div>
</section>

<!-- ==== Footer ==== -->
<app-front-end-footer></app-front-end-footer>