<footer class="footer ">
    <div class="container-fluid">
        <nav class="pull-left">
        </nav>
        <div class="copyright pull-right">
            &copy;
            {{test | date: 'yyyy'}},
            <a href="#" target="_blank">Shriji LEO Paints and Chemicals</a>
        </div>
    </div>
</footer>
