<app-header></app-header>
<!-- ==== Header ==== -->
<app-page-title heading1="Facilitators Details" heading2="Prem Sadhana"></app-page-title>

<section class="section-padding">
    <div class="container">
      <div class="row">
        <div class="col-sm-12">
        <div class="facilitation-box">
          <img src="assets/img/prem-sadhana.png" class="img-fluid">
            <p>I am born in Germany. After my master degree in Clinical Psychology I started working with children. After some years I took a training in counselling and Gestalt-Therapy. For 15 years I was working as a Psychotherapist in my private clinic in Munich. Since 1997 I came regularly to the OSHO MEDITATION</p>
            <p>RESORT where I was trained in OSHO’s Meditative Therapies (OSHO MYSTIC ROSE, OSHO BORN AGAIN, OSHO NO-MIND) and WHO-IS-IN? Am also trained as Meditation Facilitator for OSHO-Meditations. During all these years I was working in the OMR and facilitated many courses. Since 2006 I live permanently in Pune.</p>
        </div>
        </div>
      </div>
    </div>
</section>

<!-- ==== Footer ==== -->
<app-front-end-footer></app-front-end-footer>