import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-kundalini-meditation',
  templateUrl: './kundalini-meditation.component.html',
  styleUrls: ['./kundalini-meditation.component.scss']
})
export class KundaliniMeditationComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
