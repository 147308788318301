<app-header></app-header>
<!-- ==== Header ==== -->
    <app-page-title heading1="ABOUT OSHO MANAN" heading2="About Osho Manan"></app-page-title>
    <!-- Main -->
    <div class="container-fluid section-padding about-osho">
        <div class="row">
            <div class="col-12 col-sm-12">
                <img src="assets/img/osho-photo.jpg" class="img-fluid">
                <h3>OSHO MANAN NEO-SANNYAS COMMUNE- BRIEF INTRODUCTION</h3>
                <p>"All great masters have created buddhafields. If a field can be created, it is even more helpful. When there are millions of people meditating, a great field is created. You are not alone; there are people ahead of you, there are people behind you. The people who are ahead are telling you, “Yes, this happens, but it disappears.” And you can tell people who are behind you, “Yes, this happens, what is happening to you, but it disappears.” You are in a chain. That’s why communes have been created, down the ages. Around each great master a commune arises – it has to arise. It creates a certain energy-field: in that energy-field, people can grow more easily. It becomes the Garden."</p>
                <h3>OSHO Manan Neo-Sannyas Commune</h3>
                <p>'OSHO' is a new beginning in the stream of Enlightenment. By presenting vision of new man ' Zorba The Buddha' to the world he started new path where Science & Religion merge together first time.</p>
                <p>During late 60's a rebellious voice emerged in the Spiritual World namely 'Acharya Shree Rajneesh' and was spread like forest fire in India . People of India were influenced through his discourses and those who were seeking for truth started to gather around him from rest of the world. In 1970, OSHO resided in Mumbai. During this period from every part of Gujarat,seekers started to take interest in Osho Discourses. They invited Osho for conducting meditation camps & discourse series. They were influenced by his exceptional clarity and deep thinking about truth and many of them joined in the flow of rebellious consciousness.</p>

                <p>Meanwhile many seekers from Mehsana City, which is situated in northern part of Gujarat, acquainted with Osho. In 1974-75 Osho started to reside in Pune. Some seekers from Mehsana met and requested Osho (At that time known as Bhagwan Shree Rajneesh) that they also wanted to contribute in propagating vision of Osho. </p>

                <p>Osho directed them to start a Meditation Centre so that seekers from Rajsthan & Gujarat can meditate there. On the same line on 31-03-1976 Rajneesh Manan Dhyan Kendra (Rajneesh Manan Meditation Centre) was established.</p>

                <p>Meanwhile many seekers from Mehsana City,which is situated in northern part of Gujarat, acquainted with Osho. In 1974-75 Osho started to reside in Pune. Some seekers from Mehsana met and requested Osho (At that time known as Bhagwan Shree Rajneesh) that they also wanted to contribute in propagating vision of Osho. <br>
                
                Osho Manan Neo Sannyas Commune  is an international commune, spread our 14 acres land, </p>

                <p>located at the outskirts of Mehsana, Gujarat. Manan Commune is inundated with nature, any</p>

                <p>number of trees, birds, and throughout the year dancing peacock. The commune offers more than</p>

                <p>8 Osho meditative   activities from 06:00 AM to 09:30 PM. In addition to that, it conducts 6 </p>

                <p>Osho meditation camps and 4 Osho Meditative Therapies (designed by Osho) a year. </p>

                <p>Every year, the commune welcomes more than 2500 visitors from across the globe. It gives </p>

                <p>visitors an opportunity to unwind their stressed minds. Meditation techniques practiced in </p>

                <p>commune do help seekers to become more aware and sensitive.</p>

                <p>Located at the heart of the commune, is an aesthetically designed gigantic Buddha Hall, which </p>

                <p>can easily accommodate up to 500 people for meditation and discourse. </p>

                <p>Osho Manan Neo Sannyas Commune has progressed by leaps & bounds, and has become one of the important Communes in India after Osho International Meditation Resort, Pune.</p>
            </div> <!-- End -->
        </div>
    </div>
<!-- ==== Footer ==== -->
<app-front-end-footer></app-front-end-footer>