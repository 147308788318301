import { Component, OnInit, TemplateRef } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { HomesliderService } from './../../../Services/homeslider.service';
import { MessagePanelService } from './../../../Services/message-panel.service';
import { Globals } from './../../../global';
import { LocalStorageService } from './../../../Services/local-storage.service';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import imageCompression from 'browser-image-compression';
import * as $ from 'jquery';

@Component({
  selector: 'app-upload-image',
  templateUrl: './upload-image.component.html',
  styleUrls: ['./upload-image.component.scss']
})
export class UploadImageComponent implements OnInit {

  modalRef: BsModalRef;
  public data: any;
  deleteData: any = {};
  eventlistgetall: any = [];
  imgbaseurl = "";
  defaultImage: any = '';
  imagename: any;
  fileToUpload: File = null;

  event_id: any;
  eventImages: any = [];
  EventImagesList: any = [];
  getbase_url: string;
  uploadButton: boolean = true;
  progessBar: boolean = false;
  uploadingProgess: boolean = false;

  constructor(public globals: Globals, private router: Router, private route: ActivatedRoute, private formBuilder: FormBuilder, private messagePanelService: MessagePanelService, private localStorageService: LocalStorageService, public homesliderService: HomesliderService, private modalService: BsModalService) {
    // this.getbase_url = this.globals.base_url;
    this.getbase_url = this.localStorageService.getBaseUrl()
  }

  eventform = this.formBuilder.group({
    files: ['', Validators.required],
  });


  ngOnInit(): void {
    this.route.params.subscribe(
      (params) => {
        console.log('params ', params);
        this.event_id = params.id;
      }
    );
    if (this.localStorageService.getuserId()) {
      this.getImageList();
    } else {
      this.messagePanelService.ShowPopupMessageWithLocalization("Invalid URL Please Try Again Later", this.globals.messageCloseTime, this.globals.messageType.error)
      this.router.navigate(['/login']);
    }
  }

  handleImageUpload(event) {
    this.uploadButton = true;
    this.progessBar = true;
    var imageFile = event.target.files;
    console.log(imageFile);
    var imageFileLength = event.target.files.length;
    console.log('length ', imageFileLength);
    if (imageFileLength > 20) {
      alert('You can not exceed 20 images');
      this.modalRef.hide();
      //event.preventDefault();
    }


    var options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true
    }

    let images = [];
    var promise = new Promise<void>(resolve => {
      $.each(imageFile, async function (idx, elm) {
        //console.log(idx, elm);
        const output = await imageCompression(elm, options)
        let newf = new File([output], elm.name, { type: 'image/jpeg' });
        images.push(newf);
        console.log('images ', images);
        console.log(imageFileLength, 'file length', images.length, 'imagelength');
        if (imageFileLength == images.length) {
          resolve();
        }
      });

    });
    let that = this;
    promise.then(function (res) {

      console.log(res, images, that.eventImages);
      that.eventImages = images;
      console.log('eventimages', that.eventImages);
      that.uploadButton = false;
      that.progessBar = false;
    }, function (e) {
      console.error(e);
    });
  }

  async addEventImages() {
    this.uploadButton = true;
    this.uploadingProgess = true;
    let obj = null;
    console.log('images ', this.eventImages);
    obj = { "event_id": this.event_id, "image": this.eventImages };

    let form_data = new FormData();
    for (let i = 0; i < this.eventImages.length; i++) {
      form_data.append('image', this.eventImages[i]);
    }
    form_data.append("event_id", this.event_id);
    this.homesliderService.eventdetails(form_data).subscribe(data => {
      console.log(data);
      if (data === null || data === undefined) {
        this.messagePanelService.ShowPopupMessageWithLocalization('An error occured, please try again later', this.globals.messageCloseTime, this.globals.messageType.error);
      } else if (data['success'] == true) {
        //modal close event
        this.modalRef.hide();
        this.eventform.reset();
        this.uploadingProgess = false;
        this.getImageList();
        this.messagePanelService.ShowPopupMessageWithLocalization("EventImages uploaded Successfully", this.globals.messageCloseTime, this.globals.messageType.success);
        setTimeout(() => {
          this.getImageList();
        }, 1000);

      } else if (data['statusCode'] == 400) {
        this.messagePanelService.ShowPopupMessageWithLocalization(data['message'], this.globals.messageCloseTime, this.globals.messageType.success);
      } else {
        this.messagePanelService.ShowPopupMessageWithLocalization(data['message'], this.globals.messageCloseTime, this.globals.messageType.error);
      }
    });
    // }
  }

  getImageList() {
    let body = { 'event_id': this.event_id }
    this.homesliderService.geteventdetails(body).subscribe(data => {
      console.log(data, "TABLE");
      if (data === null || data === undefined) {
        this.messagePanelService.ShowPopupMessageWithLocalization('An error occured, please try again later', this.globals.messageCloseTime, this.globals.messageType.error);
      } else if (data['success'] === true) {
        console.log(data, "come success");
        if (data.EventDetail.length > 0) {
          this.EventImagesList = data.EventDetail;
        } else {
          this.EventImagesList = data.EventDetail;
          this.messagePanelService.ShowPopupMessageWithLocalization(data['message'], this.globals.messageCloseTime, this.globals.messageType.success);
        }
      } else if (data['statusCode'] == 400) {
        this.messagePanelService.ShowPopupMessageWithLocalization(data['message'], this.globals.messageCloseTime, this.globals.messageType.success);
      } else {
        this.messagePanelService.ShowPopupMessageWithLocalization(data['message'], this.globals.messageCloseTime, this.globals.messageType.error);
      }
    });
  }
  deleteEventImagedone(data) {
    console.log('delete data ', data);
    this.homesliderService.deleteimageupload(data).subscribe(data => {
      if (data === null || data === undefined) {
        this.messagePanelService.ShowPopupMessageWithLocalization('An error occured, please try again later', this.globals.messageCloseTime, this.globals.messageType.error);
      } else if (data['success'] == true) {
        //modal close event
        this.messagePanelService.ShowPopupMessageWithLocalization("Image Successfully Deleted", this.globals.messageCloseTime, this.globals.messageType.success);
        this.deleteData = {};
        this.modalRef.hide();

        setTimeout(() => {
          this.getImageList();
        }, 1000);
      } else if (data['statusCode'] == 400) {
        this.messagePanelService.ShowPopupMessageWithLocalization(data['message'], this.globals.messageCloseTime, this.globals.messageType.success);
      } else {
        this.messagePanelService.ShowPopupMessageWithLocalization(data['message'], this.globals.messageCloseTime, this.globals.messageType.error);
      }
    });
  }

  openAddModal(template: TemplateRef<any>) {
    this.uploadButton = true;
    this.modalRef = this.modalService.show(template, {
      class: 'modal-lg',
      keyboard: false,
      backdrop: 'static'
    });
  }
  openDeleteModal(template: TemplateRef<any>, event) {
    this.deleteData = event ? event : {};
    this.modalRef = this.modalService.show(template, {
      class: 'modal-lg',
      keyboard: false,
      backdrop: 'static'
    });
  }
}
