<app-header></app-header>
<!-- ==== Header ==== -->
    <app-page-title heading1="OSHO MYSTIC ROSE" heading2="Osho Mystic Rose"></app-page-title>
    <section class="section-padding">
        <div class="container-fluid">
          <div class="row">
            <div class="col-sm-12">
            <div class="meditation-box">
              <img src="assets/img/rose_large.jpg" class="img-fluid">
              <p> <i> “I have invented many meditations, but perhaps this will be the most essential and fundamental one.” Osho</i></p>

              <p>
                <strong>Laugh, Cry, and Let the Scars of the Past Be Dissolved in Silence</strong>
                For 21 days, Laugh for 3 hours a day for 7 days, Cry for 3 hours a day for 7 days, and then Sit Silently, for 3 hours a day for 7 days. The course includes OSHO Dynamic Meditation for the 3rd week, and optionally for the first two weeks, and OSHO Kundalini Meditation and the OSHO Evening Meeting every day.
              </p>

              <p>
                Whenever any experience is not fully lived in the moment, it leaves a residue inside us. It may be a small thing or something really significant but those residues or scars stay in the unconscious blocking our natural ability to flower to our full potential. 
              </p>

              <p>
                Whatever is left in the unconscious remains, waiting for an opportunity to be expressed, accepted, and resolved. 
              </p>

            <p>
                When we allow all our un-laughed laughter, our un-cried tears, and our un-lived silence to be experienced, we can allow whatever is in the unconscious to flow freely and be lived now – and be resolved forever. 
            </p>

            <p>
                The laughter, tears and silence do their work without the need for words, discussion, or analysis as is typical of conventional “therapy.” You just participate with totality and sincerity, and the rest happens by itself. 
            </p>

            <p>
                The OSHO Mystic Rose provides that opportunity.
            </p>

            <p>
                <strong>Details of this 21-day course:</strong>
                As Osho explains: 
            </p>

            <p>
                <strong> For seven days we laugh for no reason at all for three hours each day.</strong> 
            </p>

            <p class="pl-3">
               <i> “The first part… for three hours, people simply laugh for no reason at all…. Digging for three hours you will be surprised how many layers of dust have gathered upon your being. It will cut them like a sword, in one blow. For seven days continuously, three hours every day… you cannot conceive how much transformation can come to your being.” </i> 
            </p>

            <p class="pl-3">
                <i>“When a man reaches into his innermost being he will find the first layer is of laughter and the second layer is of agony, tears.”
                </i>
            </p>

            <p>
                <strong>For the second seven days, we cry for no reason at all for three hours a day.</strong>
            </p>

            <p class="pl-3">
                <i>“So for seven days you have to allow yourself to weep, cry, for no reason at all -- just the tears are ready to come.” 
                </i> 
             </p>
 
             <p class="pl-3">
                 <i>“The first part removes everything that hinders your laughter -- all the inhibitions of past humanity, all the repressions. It cuts them away. It brings a new space within you, but still you have to go a few steps more to reach the temple of your being, because you have suppressed so much sadness, so much despair, so much anxiety, so many tears -- they are all there, covering you and destroying your beauty, your grace, your joy.”
                 </i>
             </p>

             <p>
                <strong>The third part is the time for the “Watcher on the Hill,” just watching whatever is happening inside or out.</strong>
            </p>

            <p class="pl-3">
                <i>“And my effort here is to take away all your scars and all your wounds and make you aware that you are just a watcher. A watcher cannot be wounded; no bullet can pass through it, no nuclear bomb can destroy it.” 
                </i> 
             </p>
 
             <p class="pl-3">
                 <i>“Just be a witness. Go on witnessing whatsoever passes in the mind, and the very process of witnessing has the whole secret in it.” 
                 </i>
             </p>

            <p class="pl-3">
                <i>“Then you know that you are just the quality of reflection, that you are a pure consciousness, a witness, that you are a mirror and nothing else, that you are just a watcher, a watcher on the hill.” 
                </i>
            </p>

            <p class="pl-3">
                <i>“That is freedom. That’s what is called liberation, nirvana. And that man knows what benediction is!”
                </i>
            </p>

            <p>
                As Osho further explains: 
            </p>

            <p class="pl-3">
                <i>“Laughter is a great medicine. It is a tremendously powerful therapy. If you can laugh at your own unconscious, the unconscious loses its force. In your very laughter your guilt, your wounds, disappear.” 
                </i>
            </p>

            <p class="pl-3">
                <i>“I am giving you a very fundamental technique, fresh and unused. And it is going to become worldwide, without any doubt, because its effects will show anybody that the person has become younger, the person has become more loving, the person has become graceful. The person has become more flexible, less fanatic; the person has become more joyful, more a celebrant.<br>
                “All that this world needs is a good cleansing of the heart of all the inhibitions of the past. Laughter and tears can do both. Tears will take out all the agony that is hidden inside you and laughter will take all that is preventing your ecstasy.” 
                </i>
            </p>

            <p class="pl-3">
                <i>“My own experience says to me that if you can laugh rightly, in the right moment, it will bring you out of unconsciousness into the open sky, from the darkness to the light. I am introducing laughter as a meditation because nothing makes you so total as laughter; nothing makes you stop your thinking as laughter does. Just for a moment you are no more a mind. Just for a moment you are no more in time. Just for a moment you have entered into another space where you are total and whole and healed.” 
                </i>
            </p>

            <p class="pl-3">
                <i>“This is absolutely my meditation.”</i>
            </p>

            </div>
            </div>
          </div>
        </div>
    </section>
<!-- ==== Footer ==== -->
<app-front-end-footer></app-front-end-footer>